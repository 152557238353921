import { eventsInMonth } from './chartCalculations';
import { getSensorDummyEvents, getAdditionalDummyEvents } from './dummyData';
import Dashboard, { applyInsectTypeFilter, applySensorFilter, sortAndAddChartInfo } from './Dashboard';
import TotalCountPanel from './TotalCountPanel';

const DummyDataTotalCountPanel = {
  extends: TotalCountPanel,
  methods: {
    calcPastDataMaxDay() {
      const originalResult = TotalCountPanel.methods.calcPastDataMaxDay.call(this);

      // Event starts on Oct 22 - have at least as many data
      return Math.max(originalResult, 22);
    },
  },
};

export default {
  title: 'Dashboard',
  extends: Dashboard,
  components: {
    TotalCountPanel: DummyDataTotalCountPanel,
  },
  data() {
    const originalData = Dashboard.data.call(this);

    return {
      ...originalData,
      additionalDummyEvents: getAdditionalDummyEvents(),
    };
  },
  computed: {
    panelData() {
      const originalResult = Dashboard.computed.panelData.call(this);

      const result = {};

      Object.keys(originalResult).forEach((chartType) => {
        const matchingData = this.findMatchingDummyData(
          chartType,
          this.sortedEventsBySensorId,
          this.selectedSensor,
          this.insectTypeFilter,
        );
        result[chartType] = matchingData || originalResult[chartType];
      });

      return result;
    },
  },
  inject: {
    fetchSensors: {
      default: () => async () => [
        { id: 's1_filling', name: 'Filling' },
        { id: 's2_packaging', name: 'Packaging' },
        { id: 's3_storage', name: 'Pre-shipment storage' },
      ],
    },
    fetchSensorEvents: {
      default: () => (sensorId) => getSensorDummyEvents(sensorId),
    },
  },
  methods: {
    findMatchingDummyData(chartType, defaultEvents, selectedSensor, insectTypeFilter) {
      const match = this.additionalDummyEvents.find(({ matchConditions }) =>
        (!matchConditions.chartType || matchConditions.chartType === chartType) &&
          (matchConditions.insectType === undefined ||
            matchConditions.insectType === insectTypeFilter),
      );
      if (!match) {
        return null;
      }

      const events = {
        ...defaultEvents,
        ...match.events,
      };

      let sortedEvents = {};
      Object.keys(events).forEach((sensorId) => {
        sortedEvents[sensorId] = sortAndAddChartInfo(events[sensorId]);
      });

      if (!selectedSensor) {
        sortedEvents = { s3_storage: sortedEvents.s3_storage };
      }

      const allMonthEvents = applyInsectTypeFilter(
        applySensorFilter(
          sortedEvents,
          this.selectedSensor,
        ),
        this.insectTypeFilter,
      );

      const currentMonthEvents = eventsInMonth(
        this.currentMonth,
        allMonthEvents,
      );

      switch (chartType) {
        case 'totalCountPanel':
        case 'dailyInsectCountChart':
          return allMonthEvents;
        case 'noticeHistory':
        case 'hourlyBarChart':
        case 'top5InsectsPanel':
        case 'weekdayBarChart':
        case 'weekdayHourMatrixChart':
          return currentMonthEvents;
        default:
          return null;
      }
    },
  },
};
