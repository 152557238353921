var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "round-button",
    {
      attrs: {
        icon: "file_download",
        "data-testid": "csv_export",
        loading: _vm.exporting,
      },
      on: { click: _vm.downloadCsv },
    },
    [_vm._v("\n  " + _vm._s(_vm.$t("button.export")) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }