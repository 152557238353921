<template>
  <entity-list-layout
    title="INSTALLATION SITES"
    :count="filteredItems.length"
    :query.sync="search"
    :search-hint="$t('search_by_text')"
  >
    <template #title-area-content>
      <create-installation-site-button v-if="creatable" />
    </template>
    <page-load-spinner v-if="loading" />
    <p-data-list :pagination.sync="pagination" :data="filteredItems" v-else>
      <data-field :name="$t('field.bookmarked')" value="bookmarked" headerClass="column--bookmarked" sortable>
        <template v-slot="{ item }">
          <div class="text-sm-center">
            <toggle-icon-button
              icon="mdi-bookmark"
              data-testid="toggle-bookmark"
              :value="!!item.bookmarked"
              @input="bookmarked => updateBookmarked(item.id, bookmarked)"
            />
          </div>
        </template>
      </data-field>
      <data-field name="No" headerClass="column--no" v-if="admin">
        <template v-slot="{ index }">{{ index + 1 }}</template>
      </data-field>
      <data-field :name="$t('field.type')" value="typeIndex" headerClass="column--type" sortable>
        <template v-slot="{ item }">
          <sensor-type-label :type="item.type" />
        </template>
      </data-field>
      <data-field :name="$t('field.name')" value="name" headerClass="column--name" sortable>
        <template v-slot="{ item }">
          <router-link
            class="break-long-lines"
            :to="siteRouterLinkTarget(item.id)"
          >
            {{ item.name }}
          </router-link>
        </template>
      </data-field>
      <data-field :name="$t('field.sensor_name')" value="sensorName" headerClass="column--sensor-name" sortable>
        <template v-slot="{ item }">
          <router-link
            class="break-long-lines"
            :to="sensorRouterLinkTarget(item.sensorId)"
          >
            {{ item.sensorName }}
          </router-link>
        </template>
      </data-field>
      <data-field
        :name="$t('field.installation_date')"
        value="installationDate"
        headerClass="column--installation-date"
        sortable
      >
        <template v-slot="{ item }">{{ item.installationDate }}</template>
      </data-field>
      <data-field
        :name="$t('field.organization_name')"
        value="organizationName"
        headerClass="column--organization-name"
        sortable
        v-if="admin"
      >
        <template v-slot="{ item }">
          <router-link
            class="break-long-lines"
            :to="{ name: 'admin-organization', params: { id: item.organizationId } }"
          >
            {{ item.organizationName }}
          </router-link>
        </template>
      </data-field>
    </p-data-list>
  </entity-list-layout>
</template>

<i18n lang="yaml">
ja:
  search_by_text: 文字列で検索
  field:
    type: 種別
    name: 設置場所名
    sensor_name: センサー名
    installation_date: 設置日
    organization_name: 組織名
    bookmarked: よく見る設置場所

en:
  search_by_text: Search by text
  field:
    type: Type
    name: Site Name
    sensor_name: Sensor Name
    installation_date: Installation Date
    organization_name: Organization Name
    bookmarked: Bookmark
</i18n>

<script>
import SensorTypes from '@/mixins/sensorTypes';

import EntityListLayout from '@/components/atoms/EntityListLayout';
import PageLoadSpinner from '@/components/atoms/PageLoadSpinner';
import SensorTypeLabel from '@/components/atoms/SensorTypeLabel';
import DataList, { Field } from '@/components/DataList';
import ToggleIconButton from '@/components/atoms/ToggleIconButton';

import CreateInstallationSiteButton from './/CreateInstallationSiteButton';

const keywordMatcher = (query, properties) => {
  const lowerCaseKeywords = query.toLowerCase().split(' ');

  return (item) => {
    const lowerCaseValues = properties.map(property => (item[property] || '').toLowerCase());

    return lowerCaseKeywords.every(
      (keyword) => lowerCaseValues.some(value => value.includes(keyword)),
    );
  };
};

export default {
  name: 'installation-site-list',
  mixins: [
    SensorTypes,
  ],
  components: {
    CreateInstallationSiteButton,
    DataField: Field,
    EntityListLayout,
    PageLoadSpinner,
    PDataList: DataList,
    SensorTypeLabel,
    ToggleIconButton,
  },
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      pagination: {
        page: 1,
        itemsPerPage: 20,
        sortBy: 'name',
      },
      search: '',
      sites: [],
    };
  },
  mounted: async function () {
    this.sites = await this.fetchInstallationSites();
    this.loading = false;
  },
  computed: {
    creatable() {
      const excepts = ['station', 'mirakun_device'];
      const { allowed_sensor_types } = this.$store.getters.getCurrentUser;
      const availableSensorTypes = this._.without(allowed_sensor_types, ...excepts);
      return availableSensorTypes.length > 0;
    },
    filteredItems() {
      return this.items.filter(
        keywordMatcher(
          this.search,
          ['name', 'sensorName', 'installationDate', 'organizationName'],
        ),
      );
    },
    items() {
      return this.sites.map((site) => {
        const item = {
          ...site,
          typeIndex: this.SENSOR_TYPE_INDEXES[site.type],
        };

        if (site.sensor) {
          item.sensorId = site.sensor.id;
          item.sensorName = site.sensor.name;
          item.installationDate = this.$moment(site.sensor.installed_at).format('YYYY/MM/DD');
        }

        if (this.admin && site.organization) {
          item.organizationId = site.organization.id;
          item.organizationName = site.organization.name;
        }

        return item;
      });
    },
  },
  inject: ['fetchInstallationSites', 'updateInstallationSite'],
  methods: {
    sensorRouterLinkTarget(sensorId) {
      const routeName = this.admin ? 'admin-sensor' : 'sensor';
      return { name: routeName, params: { id: sensorId } };
    },
    siteRouterLinkTarget(siteId) {
      const routeName = this.admin ? 'admin-installation_site' : 'installation_site';
      return { name: routeName, params: { id: siteId } };
    },
    updateBookmarked: async function (siteId, value) {
      const site = await this.updateInstallationSite(siteId, { bookmarked: value });
      const index = this.sites.findIndex(s => s.id === site.id);
      this.$set(this.sites, index, site);
    },
  },
};
</script>

<style scoped lang="sass">
::v-deep
  .break-long-lines
    word-break: break-word

  .column
    &--no
      width: 48px

    &--type
      width: 180px

    &--name
      min-width: 180px
      width: 100%

    &--sensor-name,
    &--organization-name
      min-width: 180px

    &--installation-date
      min-width: 130px

    &--bookmarked
      min-width: 80px
</style>
