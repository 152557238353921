<template>
  <v-row dense class="align-center">
    <v-col>
      <tag-display :editable="editable" :event="event" @remove="onRemoveTag" />
    </v-col>
    <v-col cols="auto">
      <gallery-view-fullscreen-enter
        :fullscreen="fullscreen"
        @input="$emit('update:fullscreen', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import GalleryViewFullscreenEnter from './FullscreenEnter';
import TagDisplay from './TagDisplay';

export default {
  name: 'GalleryViewHeader',
  components: {
    GalleryViewFullscreenEnter,
    TagDisplay,
  },
  props: {
    editable: Boolean,
    event: Object,
    fullscreen: Boolean,
  },
  methods: {
    onRemoveTag(tag) {
      const newTags = this.event.tags.filter(t => !this._.isEqual(t, tag));
      this.$emit('updateEvent', { tags: newTags });
    },
  },
};
</script>
