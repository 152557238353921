<template>
  <div class="tag-display">
    <div class="tag-display__icon">
      <v-icon>fa-tag</v-icon>
    </div>
    <div class="tag-display__tag-list">
      <v-chip
        v-for="t in sortedTags"
        :key="t"
        label
        :close="editable"
        @click:close="$emit('remove', t)"
      >
        {{t}}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tag-display',
  props: {
    event: {
      type: Object,
      default() {
        return {
          tags: [],
        };
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    sortedTags() {
      return this.event.tags.sort();
    },
  },
  methods: {
    removeTag(tag) {
      const newTags = this.event.tags.filter(t => !this._.isEqual(t, tag));

      this.$store.dispatch('updateSensorEvent', {
        event: this.event,
        attributes: { tags: newTags },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.tag-display
  display: flex
  align-items: center
  padding: 0.5rem 0
  height: 50px

  .v-chip
    margin: 0.125rem 0.25rem
</style>
