var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-md-flex" }, [
    _c(
      "div",
      { staticClass: "entity-form__form my-3" },
      [
        _c(
          "v-form",
          {
            ref: "form",
            model: {
              value: _vm.valid,
              callback: function ($$v) {
                _vm.valid = $$v
              },
              expression: "valid",
            },
          },
          [_vm._t("form", null, { editing: _vm.editing })],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "entity-form__button-section my-6 mx-md-10 my-md-0" },
      [
        _vm.editing
          ? [
              _c(
                "round-button",
                {
                  staticClass: "sensor-form__btn my-3",
                  attrs: { icon: "fa-check-circle", fullWidth: "" },
                  on: { click: _vm.save },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.save")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "round-button",
                {
                  staticClass: "sensor-form__btn my-3",
                  attrs: { icon: "fa-arrow-circle-left", fullWidth: "" },
                  on: { click: _vm.cancel },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.cancel")) + "\n      "
                  ),
                ]
              ),
            ]
          : [
              _c(
                "round-button",
                {
                  staticClass: "sensor-form__btn my-3",
                  attrs: { icon: "fa-pencil-alt", fullWidth: "" },
                  on: {
                    click: function ($event) {
                      _vm.editing = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.edit")) + "\n      "
                  ),
                ]
              ),
            ],
        _vm._v(" "),
        _vm._t("buttons", null, { editing: _vm.editing }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }