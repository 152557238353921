var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.featureIsActive("sensor_installation_sites")
    ? _c("activity-legacy")
    : _c(
        "div",
        [
          _c(
            "page-title-area",
            {
              scopedSlots: _vm._u([
                {
                  key: "side-content",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: { click: _vm.expandAllSites },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { left: "", color: "primary" } },
                            [_vm._v("fa-chevron-circle-down")]
                          ),
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("expand_all_button")) +
                              "\n      "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("h1", [_vm._v("ACTIVITY")])]
          ),
          _vm._v(" "),
          _c(
            "query-filter",
            {
              staticClass: "mb-4",
              attrs: {
                loading: _vm.loading,
                clearable: _vm.displaysAnyEvents || _vm.hasQuery,
              },
              on: { apply: _vm.executeQuery, clear: _vm.clearQuery },
            },
            [
              _c(
                "v-row",
                { staticClass: "mb-4", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "selectbox",
                        attrs: {
                          value: _vm.queryOfInstallationSiteType,
                          items: _vm.installationSiteTypeItems,
                          placeholder: _vm.$t("query.type"),
                          "hide-details": "",
                          clearable: "",
                          outlined: "",
                          disabled: _vm.hasInstallationSiteId,
                        },
                        on: {
                          input: function (value) {
                            return (_vm.query.installationSiteType = value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("autocomplete-text-field", {
                        attrs: {
                          "allow-new-values": "",
                          clearable: "",
                          value: _vm.queryOfInstallationSiteName,
                          label: _vm.$t("query.name"),
                          items: _vm.installationSiteNameItems,
                          icon: "location_searching",
                          "data-testid": "installation_site_name_field",
                          disabled: _vm.hasInstallationSiteId,
                        },
                        on: {
                          input: function (value) {
                            return (_vm.query.installationSiteName = value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("term-picker", {
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          placeholder:
                                            _vm.$t("query.time_period"),
                                          "hide-details": "",
                                          outlined: "",
                                          dense: "",
                                          "prepend-inner-icon": "fa-calendar",
                                          readonly: "",
                                          value: _vm.timePeriodLabel,
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.query.timePeriod,
                          callback: function ($$v) {
                            _vm.$set(_vm.query, "timePeriod", $$v)
                          },
                          expression: "query.timePeriod",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("tag-picker", {
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          placeholder: _vm.$t("query.tag"),
                                          "hide-details": "",
                                          outlined: "",
                                          dense: "",
                                          "prepend-inner-icon": "local_offer",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.tagsLabel,
                                          callback: function ($$v) {
                                            _vm.tagsLabel = $$v
                                          },
                                          expression: "tagsLabel",
                                        },
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.query.tags,
                          callback: function ($$v) {
                            _vm.$set(_vm.query, "tags", $$v)
                          },
                          expression: "query.tags",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-md-center" }, [
                _c(
                  "div",
                  { staticClass: "checkbox" },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox__input",
                      attrs: { "hide-details": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "checkbox__label" },
                                [
                                  _c("v-icon", [_vm._v("star")]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("query.pickup_only"))),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.query.pickupOnly,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "pickupOnly", $$v)
                        },
                        expression: "query.pickupOnly",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox" },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox__input",
                      attrs: { "hide-details": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "checkbox__label" },
                                [
                                  _c("v-icon", [
                                    _vm._v("sentiment_very_dissatisfied"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("query.misdetected_only"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.query.misdetectedOnly,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "misdetectedOnly", $$v)
                        },
                        expression: "query.misdetectedOnly",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox" },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox__input",
                      attrs: { "hide-details": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "checkbox__label" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("query.without_misdetection"))
                                  ),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.query.withoutMisdetection,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "withoutMisdetection", $$v)
                        },
                        expression: "query.withoutMisdetection",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox" },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox__input",
                      attrs: { "hide-details": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "checkbox__label" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("query.exceeded_only"))),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.query.exceededOnly,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "exceededOnly", $$v)
                        },
                        expression: "query.exceededOnly",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox" },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox__input",
                      attrs: { "hide-details": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "checkbox__label" },
                                [
                                  _c("v-icon", [_vm._v("fa-eye-slash")]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("query.with_hidden"))),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.query.withHidden,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "withHidden", $$v)
                        },
                        expression: "query.withHidden",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox" },
                  [
                    _c("v-checkbox", {
                      staticClass: "checkbox__input",
                      attrs: {
                        "hide-details": "",
                        "data-testid": "bookmarked_checkbox",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "checkbox__label" },
                                [
                                  _c("v-icon", [_vm._v("mdi-bookmark")]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("query.bookmarked_only"))
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.query.bookmarkedOnly,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "bookmarkedOnly", $$v)
                        },
                        expression: "query.bookmarkedOnly",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right pt-4" },
            [
              _c("insect-count-line-charts-button", {
                attrs: { "sites-with-event-loaders": _vm.eventLoadersWithData },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.eventLoadersWithData, function (ref, index) {
            var installationSite = ref.installationSite
            var eventLoader = ref.eventLoader
            return [
              _c(
                "div",
                { key: installationSite.id },
                [
                  _c("installation-site-events", {
                    attrs: {
                      "installation-site": installationSite,
                      eventLoader: eventLoader,
                      expanded: _vm.expandedSiteIds.includes(
                        installationSite.id
                      ),
                    },
                    on: {
                      "update:expanded": function (value) {
                        return _vm.updateSiteExpandedState(
                          installationSite.id,
                          value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  index < _vm.eventLoadersWithData.length - 1
                    ? _c("v-divider", { staticClass: "mb-4 mt-8" })
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }