var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "elevation-0 tag",
      attrs: { block: "" },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("div", { staticClass: "tag__name" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("v-icon", { staticClass: "tag__icon", attrs: { right: "" } }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }