<template>
  <v-card class="gallery-view">
    <gallery-view-toolbar
      :editable="editable"
      :event="event"
      :sensor="sensor"
      :canDownload="canDownload"
      :title="title"
      @update="(attr) => $emit('updateEvent', attr)"
      @download="download"
      @close="$emit('close')"
    >
      <template v-if="editable">
        <toolbar-button
          icon="fa-trash"
          @click.native.stop="$emit('deleteEvent')"
        >
          {{ $t('menu.delete') }}
        </toolbar-button>
        <toolbar-button
          icon="fa-frown-o"
          :value="event.misdetection"
          @click.native="toggleMisdetection"
        >
          {{ $t('menu.false_positive') }}
        </toolbar-button>
      </template>
    </gallery-view-toolbar>
    <layout
      :hasPrevious="hasPrevious"
      :hasNext="hasNext"
      @previous="$emit('previous')"
      @next="$emit('next')"
      :sideColumns="2"
    >
      <template #top>
        <gallery-view-header
          :editable="editable"
          :event="event"
          :fullscreen.sync="fullscreen"
          @updateEvent="$emit('updateEvent', $event)"
        />
      </template>
      <div class="media mb-2">
        <event-video
          class="flex"
          :src="video"
          :thumbnail="thumbnail"
          :fullscreen="fullscreen"
          @enterFullscreen="fullscreen = true"
          @exitFullscreen="fullscreen = false"
        />
      </div>
      <slot name="thumbnails" />
      <template #side>
        <view-mode-selector :event="event" v-model="viewMode" />
      </template>
    </layout>
  </v-card>
</template>

<i18n lang="yaml">
ja:
  menu:
    delete: 削除
    false_positive: '誤検出'

en:
  menu:
    delete: Delete
    false_positive: 'False positive'
</i18n>

<script>
import ToolbarButton from '@/components/atoms/ToolbarButton';
import EventVideo from '@/components/GalleryView/EventVideo';
import GalleryViewHeader from '@/components/GalleryView/GalleryViewHeader';
import GalleryViewToolbar from '@/components/GalleryView/Toolbar';
import Layout from '@/components/GalleryView/Layout';
import ViewModeSelector from './ViewModeSelector';

export default {
  name: 'RatView',
  props: {
    editable: Boolean,
    event: Object,
    sensor: Object,
    hasPrevious: Boolean,
    hasNext: Boolean,
    title: String,
  },
  data() {
    return {
      fullscreen: false,
      viewMode: 'detection',
    };
  },
  inject: ['downloadAttachment'],
  components: {
    EventVideo,
    Layout,
    GalleryViewHeader,
    GalleryViewToolbar,
    ToolbarButton,
    ViewModeSelector,
  },
  computed: {
    resourceType() {
      return this.viewMode === 'original' ? 'original_video' : 'video';
    },
    thumbnail() {
      const { attachments } = this.event;
      return attachments.thumbnail || attachments.photo;
    },
    video() {
      const { attachments } = this.event;
      if (this.viewMode === 'original') {
        return attachments.original_video;
      }
      return attachments.video;
    },
    canDownload() {
      return !this.event.updating && !!this.event.attachments[this.resourceType];
    },
  },
  methods: {
    toggleMisdetection() {
      this.$emit('updateEvent', { misdetection: !this.event.misdetection });
    },
    download: async function () {
      this.downloadAttachment(this.event.id, this.resourceType);
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.media
  @media #{map-get($display-breakpoints, 'md-and-up')}
    max-width: 64%
    margin: 0 auto

  .event-image:not(.event-image--fullscreen),
  .event-video
    @media #{map-get($display-breakpoints, 'md-and-up')}
      max-width: 80%
      margin: 0 auto

  ::v-deep .vjs-volume-menu-button
    display: none
</style>
