<template>
  <round-button
    icon="file_download"
    data-testid="csv_export"
    :loading="exporting"
    @click="downloadCsv"
  >
    {{ $t('button.export') }}
  </round-button>
</template>

<i18n lang="yaml">
ja:
  button:
    export: CSV出力
  msg:
    export:
      failed: CSVの出力に失敗しました。
  csv:
    headers:
      number: No
      organization_name: 組織名
      user_name: ユーザー名
      installation_site: 設置場所
      installed_on: 設置日
      threshold: アップロード時のしきい値
      increase_threshold: アップロード時の増加量のしきい値
      memo: メモ
      captured_at: 撮影日時
      rat_timestamp: 動画開始日時
      total_count: 合計
      detected: 出現
      detected_from: 出現開始日時
      detected_to: 出現終了日時
      pickup: ピックアップ
      misdetection: 誤検出
      tags: タグ

en:
  button:
    export: Export CSV
  msg:
    export:
      failed: Failed to output CSV.
  csv:
    headers:
      number: No
      organization_name: Organization name
      user_name: User name
      installation_site: Place
      installed_on: Installed on
      threshold: Threshold when photo was uploaded
      increase_threshold: Increase Threshold when photo was uploaded
      memo: Memo
      captured_at: Captured at
      rat_timestamp: Start capture at
      total_count: Total
      detected: Detected
      detected_from: Detected from
      detected_to: Detected to
      pickup: Pickup
      misdetection: Misdetection
      tags: Tags
</i18n>

<script>
import { exportCsv, rowArrayToCSVString } from '@/libs/csv';

import InsectTypes from '@/mixins/insectTypes';
import Notifications from '@/mixins/notifications';

import RoundButton from '@/components/atoms/RoundButton';

import { csvColumnsForSite } from './csv';

export default {
  name: 'CsvExportButton',
  mixins: [
    InsectTypes,
    Notifications,
  ],
  components: {
    RoundButton,
  },
  props: {
    eventLoader: Object,
    installationSite: Object,
  },
  computed: {
    csvColumns() {
      return csvColumnsForSite(this.installationSite);
    },
    csvHeaders() {
      return this.csvColumns.map(column => {
        const { id } = column;

        switch (id) {
          case 'insect':
            return this.INSECT_TYPE_NAMES[column.insectType];
          default:
            return this.$t(`csv.headers.${id}`);
        }
      });
    },
    exporting() {
      return this.csvEventLoader.loading;
    },
  },
  data() {
    return {
      csvEventLoader: {},
    };
  },
  methods: {
    buildCsvData(events) {
      return events.map((event, index) =>
        this.csvColumns.map(column =>
          column.calc({
            event,
            no: index + 1,
            site: this.installationSite,
          }),
        ),
      );
    },
    downloadCsv: async function () {
      try {
        await this.csvEventLoader.loadAll();
        const csvData = [
          this.csvHeaders,
          ...this.buildCsvData(this.csvEventLoader.events),
        ];
        exportCsv(rowArrayToCSVString(csvData), `${this.installationSite.name}.csv`);
      } catch (error) {
        this.notifyError('msg.export.failed');
        throw error;
      }
    },
  },
  mounted() {
    this.csvEventLoader = this.eventLoader.emptyCopyWithAdditionalParams({
      with_undetected: '1',
    });
  },
};
</script>
