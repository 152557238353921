var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "round-button",
        {
          attrs: {
            icon: "show_chart",
            "data-testid": "insect_count_charts",
            disabled: _vm.applicableSites.length === 0,
          },
          on: { click: _vm.openDialog },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("display_insect_graphs")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: {
            persistent: "",
            "content-class": "insect-count-line-charts-dialog",
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("dialog-action", { on: { click: _vm.close } }, [
                    _vm._v(_vm._s(_vm.$t("close"))),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogVisible,
            callback: function ($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c("month-picker", {
                    staticClass: "text-h4",
                    model: {
                      value: _vm.month,
                      callback: function ($$v) {
                        _vm.month = $$v
                      },
                      expression: "month",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                _vm._l(_vm.applicableSites, function (ref) {
                  var installationSite = ref.installationSite
                  var eventLoader = ref.eventLoader
                  return _c(
                    "v-col",
                    {
                      key: installationSite.id,
                      staticClass: "graph mt-12",
                      attrs: { cols: "4", "data-testid": "chart_area" },
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "text-center d-block text-truncate py-1",
                          attrs: { title: installationSite.name },
                        },
                        [_vm._v(_vm._s(installationSite.name))]
                      ),
                      _vm._v(" "),
                      _c("site-chart", {
                        attrs: {
                          "event-loader": eventLoader,
                          month: _vm.month,
                          yMax: 10,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }