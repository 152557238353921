var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.eventsLoading
    ? _c(
        "div",
        { staticClass: "d-flex flex-column" },
        [
          _c("v-progress-circular", {
            staticClass: "primary--text ma-auto",
            attrs: { indeterminate: "", size: "100" },
          }),
        ],
        1
      )
    : _c("daily-insect-count-chart", {
        attrs: {
          "event-data": _vm.events,
          month: _vm.month,
          "y-max": _vm.yMax,
        },
        on: {
          "max-count-updated": function (value) {
            return _vm.$emit("max-count-updated", value)
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }