// Biggest event JSON is around 2KB
// Max Response size is 6MB
// To be safe, we load 2000 events (~4MB) at a time
const LOAD_ALL_BATCH_SIZE = 2000;

const calculateProgress = (events, oldestEvent) => {
  const newestEvent = events[0];
  const totalTimespan = newestEvent.timestamp - oldestEvent.timestamp;
  if (totalTimespan === 0) {
    return 1;
  }

  const oldestLoadedEvent = events[events.length - 1];
  const loadedTimespan = newestEvent.timestamp - oldestLoadedEvent.timestamp;

  return loadedTimespan / totalTimespan;
};

const sleep = seconds => new Promise(resolve => setTimeout(resolve, seconds));

export const buildEventLoader = (options) => {
  const {
    fetchEvent,
    fetchEvents,
    deleteEvent,
    updateEvent,
    params,
  } = options;

  const loader = {
    events: [],
    loading: false,
    finished: false,
    progress: 0,
  };

  const withLoadingState = (fn) => async (...args) => {
    loader.loading = true;

    try {
      return await fn(...args);
    } finally {
      loader.loading = false;
    }
  };

  // Keep as separate function to be used inside loadAll without updating the loading state
  const loadNext = async (count) => {
    const lastEvent = loader.events[loader.events.length - 1];

    const fetchParams = { ...params, limit: count };
    if (lastEvent) {
      fetchParams.older_than_event = lastEvent.id;
    }

    const response = await fetchEvents(fetchParams);

    loader.events = loader.events.concat(response);
    loader.finished = response.length < count;
    return response;
  };

  const loadAllState = {
    cancelled: false,
  };

  loader.loadAll = withLoadingState(async () => {
    loadAllState.cancelled = false;

    const [oldestEvent] = await fetchEvents({ ...params, limit: 1, ascending: '1' });

    while (!loader.finished) {
      // eslint-disable-next-line no-await-in-loop
      await loadNext(LOAD_ALL_BATCH_SIZE);

      loader.progress = calculateProgress(loader.events, oldestEvent);

      if (loadAllState.cancelled) {
        loader.progress = 0;
        loader.events = [];
        loader.finished = false;
        break;
      }
    }

    return loader.events;
  });

  loader.cancelLoading = () => {
    loadAllState.cancelled = true;
  };

  loader.loadNext = withLoadingState(loadNext);

  loader.copy = () => {
    const copy = buildEventLoader(options);
    copy.events = [...loader.events];
    copy.finished = loader.finished;
    copy.progress = loader.progress;
    return copy;
  };

  loader.emptyCopyWithAdditionalParams = (additionalParams) => buildEventLoader({
    ...options,
    params: {
      ...params,
      ...additionalParams,
    },
  });

  const replaceEvent = (event) => {
    const index = loader.events.findIndex(ev => ev.id === event.id);
    if (index !== -1) {
      loader.events[index] = event;
    }
    loader.events = [...loader.events];
  };

  loader.pollEvent = async (eventId, retries = 5) => {
    /* eslint-disable no-await-in-loop */
    let event;
    while (retries >= 0) {
      await sleep(2000);
      event = await fetchEvent(eventId);

      if (!event.updating) {
        break;
      }
      retries -= 1;
    }
    /* eslint-enable no-await-in-loop */

    replaceEvent(event);
  };

  loader.updateEvent = async (eventId, attributes) => {
    const response = await updateEvent(eventId, attributes);
    replaceEvent(response);
  };

  loader.deleteEvent = async (eventId) => {
    await deleteEvent(eventId);
    loader.events = loader.events.filter(ev => ev.id !== eventId);
  };

  return loader;
};
