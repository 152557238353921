var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "entity-list-layout",
    {
      attrs: {
        title: "INSTALLATION SITES",
        count: _vm.filteredItems.length,
        query: _vm.search,
        "search-hint": _vm.$t("search_by_text"),
      },
      on: {
        "update:query": function ($event) {
          _vm.search = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "title-area-content",
          fn: function () {
            return [
              _vm.creatable ? _c("create-installation-site-button") : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.loading
        ? _c("page-load-spinner")
        : _c(
            "p-data-list",
            {
              attrs: { pagination: _vm.pagination, data: _vm.filteredItems },
              on: {
                "update:pagination": function ($event) {
                  _vm.pagination = $event
                },
              },
            },
            [
              _c("data-field", {
                attrs: {
                  name: _vm.$t("field.bookmarked"),
                  value: "bookmarked",
                  headerClass: "column--bookmarked",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "text-sm-center" },
                          [
                            _c("toggle-icon-button", {
                              attrs: {
                                icon: "mdi-bookmark",
                                "data-testid": "toggle-bookmark",
                                value: !!item.bookmarked,
                              },
                              on: {
                                input: function (bookmarked) {
                                  return _vm.updateBookmarked(
                                    item.id,
                                    bookmarked
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.admin
                ? _c("data-field", {
                    attrs: { name: "No", headerClass: "column--no" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var index = ref.index
                            return [_vm._v(_vm._s(index + 1))]
                          },
                        },
                      ],
                      null,
                      false,
                      3036303403
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("data-field", {
                attrs: {
                  name: _vm.$t("field.type"),
                  value: "typeIndex",
                  headerClass: "column--type",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c("sensor-type-label", { attrs: { type: item.type } }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("data-field", {
                attrs: {
                  name: _vm.$t("field.name"),
                  value: "name",
                  headerClass: "column--name",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "break-long-lines",
                            attrs: { to: _vm.siteRouterLinkTarget(item.id) },
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(item.name) + "\n        "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("data-field", {
                attrs: {
                  name: _vm.$t("field.sensor_name"),
                  value: "sensorName",
                  headerClass: "column--sensor-name",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "break-long-lines",
                            attrs: {
                              to: _vm.sensorRouterLinkTarget(item.sensorId),
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.sensorName) +
                                "\n        "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("data-field", {
                attrs: {
                  name: _vm.$t("field.installation_date"),
                  value: "installationDate",
                  headerClass: "column--installation-date",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [_vm._v(_vm._s(item.installationDate))]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.admin
                ? _c("data-field", {
                    attrs: {
                      name: _vm.$t("field.organization_name"),
                      value: "organizationName",
                      headerClass: "column--organization-name",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "break-long-lines",
                                  attrs: {
                                    to: {
                                      name: "admin-organization",
                                      params: { id: item.organizationId },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(item.organizationName) +
                                      "\n        "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1025949369
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }