import * as _ from 'lodash';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import insectIds from 'Data/insect_ids.json';
import insectDetails from 'Data/insect_details.json';
import mixinLocale from './common/locale';

Vue.use(VueI18n);

const SYMBOLS = insectIds.versions;

export const LATEST_VERSION = Math.max(...Object.keys(SYMBOLS).map(v => parseInt(v, 10)));

export const insectTypeListForVersion = (insectTypesVersion) => {
  const symbols = SYMBOLS[insectTypesVersion];
  return _.map(symbols, (symbol, type) => buildInsectType({ type, symbol }));
};

const messages = {
  en: {
    aburamushi: 'Aphididae',
    azamiuma: 'Thysanoptera',
    chatatemushi_rui: 'Psocodea (winged)',
    choubae_ka: 'Psychodidae',
    funkobae_ka: 'Sphaeroceridae',  // funkobae_ka and hayatobibae_ka are same
    gaganbo_rui: 'Crane fly',
    haneari_rui: 'Winged ant',
    hanekakushi_ka: 'Staphylinidae',
    hayatobibae_ka: 'Sphaeroceridae',
    ka_ka: 'Culicidae',
    katsuobushimushi_ka: 'Dermestidae',
    kinokobae: 'Mycetophilidae',
    kogatahachi_rui: 'Small Hymenoptera',
    kurobanekinokobae_ka: 'Dark-winged fungus gnat',
    nisekebae_ka: 'Scatopsidae',
    nomibae_ka: 'Phoridae',
    noshimemadarameiga: 'Plodia interpunctella',
    nukaka_ka: 'Ceratopogonidae',
    oogatabae_rui: 'Large fly',
    shibanmushi_ka: 'Anobiidae',
    shoga_rui: 'Small Moth',
    shoujoubae_ka: 'Drosophilidae',
    tamabae_ka: 'Cecidomyiidae',
    tobigera_moku: 'Trichoptera',
    togehanebae: 'Heleomyzidae',
    unnka_yokobai: 'Plant hopper',
    yusurika_ka: 'Chironomidae',
    others: 'Other',
  },
  ja: {
    aburamushi: 'アブラムシ科',
    azamiuma: 'アザミウマ目',
    chatatemushi_rui: 'チャタテムシ類',
    choubae_ka: 'チョウバエ科',
    funkobae_ka: 'フンコバエ科',
    gaganbo_rui: 'ガガンボ類',
    haneari_rui: '羽アリ類',
    hanekakushi_ka: 'ハネカクシ科',
    hayatobibae_ka: 'ハヤトビバエ',
    ka_ka: 'カ科',
    katsuobushimushi_ka: 'カツオブシムシ科',
    kogatahachi_rui: '小型ハチ類',
    kinokobae: 'キノコバエ科',
    kurobanekinokobae_ka: 'クロバネキノコバエ科',
    nisekebae_ka: 'ニセケバエ科',
    nomibae_ka: 'ノミバエ科',
    noshimemadarameiga: 'ノシメマダラメイガ',
    nukaka_ka: 'ヌカカ科',
    oogatabae_rui: '大型バエ類',
    shibanmushi_ka: 'シバンムシ科',
    shoga_rui: '小ガ類',
    shoujoubae_ka: 'ショウジョウバエ科',
    tamabae_ka: 'タマバエ科',
    tobigera_moku: 'トビケラ目',
    togehanebae: 'トゲハネバエ科',
    unnka_yokobai: 'ウンカ・ヨコバイ類',
    yusurika_ka: 'ユスリカ科',
    others: 'その他',
  },
};

const i18n = new VueI18n({ locale: 'en', messages });
export default {
  computed: {
    INSECT_TYPES() {
      // バージョンを指定しないと、最新を使う（CSVインポート）
      const dataVersion = this.dataVersion || LATEST_VERSION;

      return this._.mapValues(SYMBOLS[dataVersion], (symbol, type) => ({
        ...buildInsectType({ type, symbol }),
        label: this.INSECT_TYPE_NAMES[type],
      }));
    },
    INSECT_TYPE_NAMES() {
      // バージョンを指定しないと、最新を使う（CSVインポート）
      const dataVersion = this.dataVersion || LATEST_VERSION;

      return this._.mapValues(SYMBOLS[dataVersion], (_symbol, type) =>
        i18n.t(type, this.i18n_locale),
      );
    },
    INSECT_TYPES_FOR_OPTION() {
      return this._.map(this.INSECT_TYPES, ({ type, symbol }) => ({
        value: type,
        text: `${symbol}. ${this.INSECT_TYPE_NAMES[type]}`,
      }));
    },
  },
  ...mixinLocale,
};

const buildInsectType = ({ type, symbol }) => {
  const details = insectDetails[type] || { route: 'outdoor', causes: [] };

  return {
    type,
    symbol,
    route: details.route,
    causes: details.causes,
  };
};
