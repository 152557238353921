var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "round-button",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "add_circle", rounded: "" } },
                    "round-button",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("button.add_installation_site")) +
                      "\n      "
                  ),
                  _c("span", { staticClass: "ml-1" }, [_vm._v("▼")]),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        _vm._l(_vm.items, function (key) {
          return _c(
            "v-list-item",
            { key: key, attrs: { to: "/installation_sites/new?type=" + key } },
            [
              _c("v-list-item-title", [
                _vm._v(_vm._s(_vm.SENSOR_TYPE_NAMES[key])),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }