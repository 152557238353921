<template>
  <v-card class="gallery-view">
    <gallery-view-toolbar
      :editable="editable"
      :event="event"
      :sensor="sensor"
      :canDownload="!event.updating"
      :downloadOptions="downloadOptions"
      :title="title"
      @download="download"
      @update="(attr) => $emit('updateEvent', attr)"
      @close="$emit('close')"
    >
      <template v-if="editable">
        <template v-if="!event.updating">
          <toolbar-button
            class="hidden-sm-and-down"
            icon="fa-edit"
            @click.native.stop="editing = true;"
          >
            {{ $t('menu.edit') }}
          </toolbar-button>
          <toolbar-button
            icon="fa-trash"
            @click.native.stop="$emit('deleteEvent')"
          >
            {{ $t('menu.delete') }}
          </toolbar-button>
        </template>
        <toolbar-button icon="fa-refresh" disabled v-else>
          {{ $t('menu.updating') }}
        </toolbar-button>
      </template>
    </gallery-view-toolbar>
    <layout
      class="mb-3"
      :hasPrevious="hasPrevious"
      :hasNext="hasNext"
      @previous="$emit('previous')"
      @next="$emit('next')"
    >
      <template #top>
        <gallery-view-header
          :editable="editable"
          :event="event"
          :fullscreen.sync="fullscreen"
          @updateEvent="$emit('updateEvent', $event)"
        />
      </template>
      <div class="gallery-view__image">
        <fullscreen class="gallery-view__image__fullscreen" v-model="fullscreen">
          <picked-insects-draw
            :imageSrc="event.attachments['display-photo']"
            :detectionsUrl="detectionsUrl"
            :hideCanvas="viewOriginal"
            :pickedDetectionType="pickedDetectionType"
            :originalImageWidth='ORIGINAL_IMAGE_WIDTH'
            :originalImageHeight='ORIGINAL_IMAGE_HEIGHT'
            @save="save"
            ref="drawer"
          />
        </fullscreen>
      </div>
      <div class="d-flex justify-center">
        <replacement-notification :sensor="sensor" class="mt-3" />
      </div>
      <template #side>
        <div class="result mb-2">
          <v-row no-gutters align="center" justify="end">
            <v-checkbox
              :label="$t('field.original')"
              v-model="viewOriginal"
              hide-details
              color="primary"
              class="mt-0 ml-3 mr-auto"
            />
            <chart-type-select v-model="chartType" />
          </v-row>
          <insect-count-table
            v-if="chartType == 'table'"
            :insectCounts="event.insect_counts"
            :showRadiobutton="!event.updating"
            :pickedDetectionType.sync="pickedDetectionType"
            showFocusButton
            tableMaxHeight="65vh"
            @click:focus="handleFocus"
          />
          <insect-count-by-type-bar-chart
            :insectCounts="event.insect_counts"
            v-if="chartType == 'bar'"
          />
          <top-5-insect-types-pie-chart
            :insectCounts="event.insect_counts"
            v-if="chartType == 'pie'"
          />
        </div>
        <div class="result pa-2">
          <div class="pt-2 px-2">
            <div class="result-item">
              <span>{{ $t('field.total') }}</span>
              <span class="mx-1">:</span>
              <span>{{ event.count }}</span>
            </div>
            <insect-count-difference class="result-item" :event="event" />
          </div>
          <hr />
          <threshold-alert-status
            class="pa-2"
            :event="event"
            :sensor="sensor"
          />
        </div>
      </template>
    </layout>
    <slot name="thumbnails" />
    <insect-detections-editor
      :event="event"
      :imageSrc="event.attachments['display-photo']"
      :detectionsUrl="detectionsUrl"
      :originalImageWidth='ORIGINAL_IMAGE_WIDTH'
      :originalImageHeight='ORIGINAL_IMAGE_HEIGHT'
      canViewDetectionDetail
      v-model="editing"
      @updated="$emit('pollEvent')"
    >
      <template #header>
        <content-header class="w-100" :title="title" titleClass="title my-1">
          <template #icon><sensor-icon :sensor="sensor" /></template>
          <template #subtitle>
            <div class="overflow-ellipsis title details">
              {{ timestamp.date }}<span class="ml-3" />{{ `${timestamp.time} ${event.count}`}}
            </div>
          </template>
        </content-header>
      </template>
    </insect-detections-editor>
  </v-card>
</template>

<i18n lang="yaml">
ja:
  menu:
    edit: 修正する
    delete: 削除
    updating: 更新中
  field:
    original: 撮影画像を表示
    total: 頭数合計
  msg:
    download:
      failed: 画像のダウンロードに失敗しました。

en:
  menu:
    edit: Edit
    delete: Delete
    updating: Updating
  field:
    original: Show original photo
    total: Total
  msg:
    download:
      failed: Failed to download image.
</i18n>

<script>
import moment from 'moment';
import Notifications from '@/mixins/notifications';
import TrainingDataDownload from '@/mixins/trainingDataDownload';

import Fullscreen from '@/components/atoms/Fullscreen';
import ToolbarButton from '@/components/atoms/ToolbarButton';
import ChartTypeSelect from '@/components/atoms/ChartTypeSelect';
import InsectCountByTypeBarChart from '@/components/organisms/InsectCountByTypeBarChart';
import Top5InsectTypesPieChart from '@/components/organisms/Top5InsectTypesPieChart';
import GalleryViewHeader from '@/components/GalleryView/GalleryViewHeader';
import GalleryViewToolbar from '@/components/GalleryView/Toolbar';
import Layout from '@/components/GalleryView/Layout';
import InsectCountDifference from '@/components/GalleryView/InsectCountDifference';
import ReplacementNotification from '@/components/GalleryView/FlyCountView/ReplacementNotification';
import ThresholdAlertStatus from '@/components/GalleryView/FlyCountView/ThresholdAlertStatus';
import ContentHeader from '@/components/ContentHeader';
import InsectCountTable from '@/components/InsectCountTable';
import InsectDetectionsEditor from '@/components/InsectDetectionsEditor';
import LoadingImage from '@/components/LoadingImage';
import SensorIcon from '@/components/SensorIcon';
import PickedInsectsDraw from '@/components/PickedInsectsDraw';
import { ZoomProvider } from '@/components/Zoom';

const ORIGINAL_IMAGE_WIDTH = 50248;
const ORIGINAL_IMAGE_HEIGHT = 5472;

export default {
  name: 'FlyView',
  mixins: [
    Notifications,
    TrainingDataDownload,
    ZoomProvider,
  ],
  props: {
    editable: Boolean,
    event: Object,
    sensor: Object,
    hasPrevious: Boolean,
    hasNext: Boolean,
    title: String,
  },
  components: {
    ChartTypeSelect,
    ContentHeader,
    Fullscreen,
    GalleryViewHeader,
    GalleryViewToolbar,
    InsectCountByTypeBarChart,
    InsectCountDifference,
    InsectCountTable,
    InsectDetectionsEditor,
    Layout,
    LoadingImage,
    PickedInsectsDraw,
    ReplacementNotification,
    SensorIcon,
    ThresholdAlertStatus,
    ToolbarButton,
    Top5InsectTypesPieChart,
  },
  computed: {
    timestamp() {
      return {
        date: moment.unix(this.event.timestamp).format('YYYY.M.D'),
        time: moment.unix(this.event.timestamp).format('HH:mm'),
      };
    },
    detectionsUrl() {
      return `/api/v2/events/${this.event.id}/insect_detections/photo`;
    },
    difference() {
      return this.event.count_difference;
    },
  },
  methods: {
    handleFocus() {
      this.$refs.drawer.focusNext();
    },
    download: async function (type) {
      switch (type) {
        case 'trainingData': {
          this.downloadAttachment(this.event.id, 'photo');
          break;
        }
        default: {
          this.save(this.$refs.drawer);
          break;
        }
      }
    },
    save: async function (canvas) {
      try {
        await canvas.export('download.png');
      } catch (error) {
        this.notifyError('msg.download.failed');
      }
    },
  },
  data() {
    return {
      editing: false,
      fullscreen: false,
      viewOriginal: false,
      chartType: 'table',
      pickedDetectionType: 'all',
      ORIGINAL_IMAGE_WIDTH,
      ORIGINAL_IMAGE_HEIGHT,
    };
  },
  inject: ['downloadAttachment'],
  filters: {
    sign(value) {
      if (value > 0) {
        return `+ ${value}`;
      } else if (value < 0) {
        return `- ${Math.abs(value)}`;
      }
      return '± 0';
    },
  },
  watch: {
    event(event, prev) {
      this.viewOriginal = false;
      if (event.id === prev.id) {
        this.$refs.drawer.refetch();
      } else {
        this.resetZoom();
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.result
  border: 2px solid #ccc

  &-item,
  ::v-deep .threshold-alert-status__field
    font-size: 15px
    font-weight: 500
    margin-bottom: 0.5rem
    display: flex
    align-items: center

.gallery-view__image
  &__fullscreen
    height: 65vh
    text-align: center
    overflow-x: auto
    overflow-y: hidden

    &.fullscreen
      height: 100vh

    ::v-deep .fullscreen-exit
      position: fixed !important

  ::v-deep .loading-image
    @media #{map-get($display-breakpoints, 'md-and-up')}
      min-height: 300px

  ::v-deep .insect-positions-canvas
    height: 100%
</style>
