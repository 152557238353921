import moment from 'moment';

import { insectTypeListForVersion, LATEST_VERSION } from '@/mixins/insectTypes';
import { siteHasCapability, SENSOR_TYPES } from '@/mixins/sensorTypes';

const CSV_DATE_FORMAT = 'YYYY/MM/DD';
const CSV_DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

const COLUMNS = {
  memo: {
    id: 'memo',
    calc: ({ site }) => site.memo,
  },
  pickup: {
    id: 'pickup',
    calc: ({ event }) => (event.pickup ? 1 : 0),
  },
  tags: {
    id: 'tags',
    calc: ({ event }) => event.tags.join(','),
  },
};

const formatOptionalTimestamp = (timestamp) =>
  (timestamp ? moment.unix(timestamp).format(CSV_DATETIME_FORMAT) : '');

export const csvColumnsForSite = (installationSite) => {
  const commonColumns = [
    {
      id: 'number',
      calc: ({ no }) => no,
    },
    {
      id: 'organization_name',
      calc: ({ site }) => site.organization.name,
    },
    {
      id: 'user_name',
      calc: ({ site }) => site.created_by.name,
    },
    {
      id: 'installation_site',
      calc: ({ site }) => site.name,
    },
    {
      id: 'installed_on',
      calc: ({ site }) => moment(site.sensor.installed_at).format(CSV_DATE_FORMAT),
    },
  ];

  if (siteHasCapability(installationSite, 'threshold')) {
    const columnsBeforeInsects = [
      {
        id: 'threshold',
        calc: ({ event }) => event.sensor_threshold,
      },
      {
        id: 'increase_threshold',
        calc: ({ event }) => (event.sensor_increase_threshold === null ? '-' : event.sensor_increase_threshold),
      },
      COLUMNS.memo,
      {
        id: 'captured_at',
        calc: ({ event }) => moment.unix(event.timestamp).format(CSV_DATETIME_FORMAT),
      },
      {
        id: 'total_count',
        calc: ({ event }) => event.count,
      },
    ];

    let insectColumns = [];
    if (siteHasCapability(installationSite, 'viewDetectionDetails')) {
      const insectTypes = insectTypeListForVersion(LATEST_VERSION);
      insectColumns = insectTypes.map(({ type }) => ({
        id: 'insect',
        insectType: type,
        calc: ({ event }) => event.insect_counts[type] || 0,
      }));
    }

    return [
      ...commonColumns,
      ...columnsBeforeInsects,
      ...insectColumns,
      COLUMNS.pickup,
      COLUMNS.tags,
    ];
  }

  if (installationSite.type === SENSOR_TYPES.RAT) {
    return [
      ...commonColumns,
      COLUMNS.memo,
      {
        id: 'rat_timestamp',
        calc: ({ event }) =>
          moment.unix(event.captured_at || event.timestamp).format(CSV_DATETIME_FORMAT),
      },
      {
        id: 'detected',
        calc: ({ event }) => (event.detected ? 1 : 0),
      },
      {
        id: 'detected_from',
        calc: ({ event }) => formatOptionalTimestamp(event.from),
      },
      {
        id: 'detected_to',
        calc: ({ event }) => formatOptionalTimestamp(event.to),
      },
      COLUMNS.pickup,
      {
        id: 'misdetection',
        calc: ({ event }) => (event.misdetection ? 1 : 0),
      },
      COLUMNS.tags,
    ];
  }

  throw new Error(`Cannot generate CSV columns for site type: ${installationSite.type}`);
};
