var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "gallery-view" },
    [
      _c(
        "gallery-view-toolbar",
        {
          attrs: {
            editable: _vm.editable,
            event: _vm.event,
            sensor: _vm.sensor,
            canDownload: _vm.canDownload,
            title: _vm.title,
          },
          on: {
            update: function (attr) {
              return _vm.$emit("updateEvent", attr)
            },
            download: _vm.download,
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _vm.editable
            ? [
                _c(
                  "toolbar-button",
                  {
                    attrs: { icon: "fa-trash" },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("deleteEvent")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("menu.delete")) + "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "toolbar-button",
                  {
                    attrs: {
                      icon: "fa-frown-o",
                      value: _vm.event.misdetection,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.toggleMisdetection.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("menu.false_positive")) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "layout",
        {
          attrs: {
            hasPrevious: _vm.hasPrevious,
            hasNext: _vm.hasNext,
            sideColumns: 2,
          },
          on: {
            previous: function ($event) {
              return _vm.$emit("previous")
            },
            next: function ($event) {
              return _vm.$emit("next")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function () {
                return [
                  _c("gallery-view-header", {
                    attrs: {
                      editable: _vm.editable,
                      event: _vm.event,
                      fullscreen: _vm.fullscreen,
                    },
                    on: {
                      "update:fullscreen": function ($event) {
                        _vm.fullscreen = $event
                      },
                      updateEvent: function ($event) {
                        return _vm.$emit("updateEvent", $event)
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "side",
              fn: function () {
                return [
                  _c("view-mode-selector", {
                    attrs: { event: _vm.event },
                    model: {
                      value: _vm.viewMode,
                      callback: function ($$v) {
                        _vm.viewMode = $$v
                      },
                      expression: "viewMode",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "media mb-2" },
            [
              _c("event-video", {
                staticClass: "flex",
                attrs: {
                  src: _vm.video,
                  thumbnail: _vm.thumbnail,
                  fullscreen: _vm.fullscreen,
                },
                on: {
                  enterFullscreen: function ($event) {
                    _vm.fullscreen = true
                  },
                  exitFullscreen: function ($event) {
                    _vm.fullscreen = false
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("thumbnails"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }