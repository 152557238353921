import moment from 'moment';
import _ from 'lodash';

import insectIds from 'Data/insect_ids.json';

import { addToMonthString } from '@/libs/time';

const randomFromArray = (array) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

const INSECT_TYPES = Object.keys(insectIds.versions['4']);

const buildGenerator = ({ insectDistribution }) => {
  let nextInsects = [];

  const randomInsect = () => {
    if (nextInsects.length === 0) {
      Object.entries(insectDistribution).forEach(([insect, count]) => {
        nextInsects = [...nextInsects, ...Array(count).fill(insect)];
      });

      nextInsects = _.shuffle(nextInsects);
    }

    let result = nextInsects.pop();

    if (result === 'random_other') {
      const otherInsectTypes = INSECT_TYPES.filter(
        (insect) => !Object.keys(insectDistribution).includes(insect),
      );
      result = randomFromArray(otherInsectTypes);
    }

    return result;
  };

  return {
    randomInsect,
  };
};

const createDummyData = (month, { sections, insectDistribution, eventProperties = {} }) => {
  const generator = buildGenerator({ insectDistribution });

  const beginDate = moment(month);
  let endDate = moment(month).add(1, 'months');

  const tomorrow = moment().add(1, 'days');
  if (endDate.isAfter(tomorrow)) {
    endDate = tomorrow.startOf('day');
  }
  // Event starts on Oct 22 - have at least as many data
  if (beginDate.isSame('2024-10-01') && endDate.isBefore('2024-10-23')) {
    endDate = moment('2024-10-23');
  }

  const result = [];
  let insectCounts = {};
  let count = 0;

  if (!eventProperties.sensor_threshold) {
    eventProperties.sensor_threshold = 40;
  }
  if (!eventProperties.sensor_increase_threshold) {
    eventProperties.sensor_increase_threshold = 3;
  }

  while (beginDate.isBefore(endDate)) {
    const day = beginDate.date();
    const section = sections.find((s) => s.until >= day);

    if (section.count > 0) {
      const increase = section.count - count;

      if (increase > 0) {
        const hour = Math.floor(Math.random() * 24);
        insectCounts = { ...insectCounts }; // Copy

        for (let i = 0; i < increase; i += 1) {
          const insect = generator.randomInsect();

          insectCounts[insect] = (insectCounts[insect] || 0) + 1;
        }

        count = _.sum(Object.values(insectCounts));

        result.push({
          timestamp: beginDate.clone().hour(hour).unix(),
          count,
          insect_counts: insectCounts,
          ...eventProperties,
        });
      }
    } else {
      insectCounts = {};
      count = 0;
    }

    result.push({
      timestamp: beginDate.clone().hour(23).unix(),
      count,
      insect_counts: insectCounts,
      ...eventProperties,
    });

    beginDate.add(1, 'days');
  }

  return result;
};

export const CURRENT_MONTH = moment().format('YYYY-MM');
const PREVIOUS_MONTH = addToMonthString(CURRENT_MONTH, -1);
const PREVIOUS_YEAR = addToMonthString(CURRENT_MONTH, -12);

const processGeneratedEvents = (eventsBySensorId) => {
  const result = {};

  Object.keys(eventsBySensorId).forEach((sensorId) => {
    let previousCount = 0;

    const processedEvents = [];

    const sortedEvents = _.sortBy(eventsBySensorId[sensorId], 'timestamp');

    sortedEvents.forEach((event) => {
      // Event JSON
      processedEvents.push({
        ...event,
        count_difference: event.count - previousCount,
        sensor_id: sensorId,
      });

      previousCount = event.count;
    });

    result[sensorId] = _.orderBy(processedEvents, ['timestamp'], ['desc']);
  });

  return result;
};

const FILLING_INSECT_DISTRIBUTION = {
  choubae_ka: 6,
  yusurika_ka: 1,
  nomibae_ka: 1,
  others: 1,
};

const STORAGE_INSECT_DISTRIBUTION = {
  yusurika_ka: 16,
  choubae_ka: 5,
  kurobanekinokobae_ka: 4,
  azamiuma: 3,
  nomibae_ka: 2,
  random_other: 1,
};

const MAX_MONTH_DAYS = 31;

const SEED_PARAMETERS = {
  FILLING_INSECT_DISTRIBUTION: {
    CURRENT_MONTH: {
      insectDistribution: FILLING_INSECT_DISTRIBUTION,
      sections: [
        { count: 6, until: 5 },
        { count: 8, until: 10 },
        { count: 9, until: 15 },
        { count: 0, until: 25 },
        { count: 4, until: 27 },
        { count: 6, until: MAX_MONTH_DAYS },
      ],
      eventProperties: {
        sensor_threshold: 9,
      },
    },
    PREVIOUS_MONTH: {
      insectDistribution: FILLING_INSECT_DISTRIBUTION,
      sections: [
        { count: 5, until: 10 },
        { count: 7, until: 12 },
        { count: 10, until: 15 },
        { count: 0, until: 25 },
        { count: 1, until: 28 },
        { count: 3, until: MAX_MONTH_DAYS },
      ],
      eventProperties: {
        sensor_threshold: 9,
      },
    },
    PREVIOUS_YEAR: {
      insectDistribution: FILLING_INSECT_DISTRIBUTION,
      sections: [
        { count: 13, until: 11 },
        { count: 15, until: 15 },
        { count: 0, until: 21 },
        { count: 1, until: 23 },
        { count: 2, until: 25 },
        { count: 8, until: MAX_MONTH_DAYS },
      ],
      eventProperties: {
        sensor_threshold: 14,
      },
    },
  },
  STORAGE_INSECT_DISTRIBUTION: {
    CURRENT_MONTH: {
      insectDistribution: STORAGE_INSECT_DISTRIBUTION,
      sections: [
        { count: 5, until: 3 },
        { count: 7, until: 8 },
        { count: 10, until: 10 },
        { count: 20, until: 15 },
        { count: 0, until: 21 },
        { count: 4, until: 27 },
        { count: 5, until: 28 },
        { count: 7, until: MAX_MONTH_DAYS },
      ],
      eventProperties: {
        sensor_threshold: 40,
      },
    },
    PREVIOUS_MONTH: {
      insectDistribution: STORAGE_INSECT_DISTRIBUTION,
      sections: [
        { count: 5, until: 3 },
        { count: 7, until: 8 },
        { count: 10, until: 10 },
        { count: 20, until: 15 },
        { count: 0, until: 21 },
        { count: 4, until: 27 },
        { count: 5, until: 28 },
        { count: 7, until: MAX_MONTH_DAYS },
      ],
      eventProperties: {
        sensor_threshold: 40,
      },
    },
    PREVIOUS_YEAR: {
      insectDistribution: STORAGE_INSECT_DISTRIBUTION,
      month: PREVIOUS_YEAR,
      sections: [
        { count: 20, until: 3 },
        { count: 25, until: 7 },
        { count: 30, until: 10 },
        { count: 40, until: 15 },
        { count: 0, until: 24 },
        { count: 7, until: 27 },
        { count: 10, until: MAX_MONTH_DAYS },
      ],
      eventProperties: {
        sensor_threshold: 30,
      },
    },
  },
};

export const getSensorDummyEvents = (sensorId) => {
  let eventsBySensorId = {};

  switch (sensorId) {
    case 's1_filling':
    case 's2_packaging':
      eventsBySensorId = {
        [sensorId]: [
          ...createDummyData(
            CURRENT_MONTH,
            SEED_PARAMETERS.FILLING_INSECT_DISTRIBUTION.CURRENT_MONTH,
          ),
          ...createDummyData(
            PREVIOUS_MONTH,
            SEED_PARAMETERS.FILLING_INSECT_DISTRIBUTION.PREVIOUS_MONTH,
          ),
          ...createDummyData(
            PREVIOUS_YEAR,
            SEED_PARAMETERS.FILLING_INSECT_DISTRIBUTION.PREVIOUS_YEAR,
          ),
        ],
      };
      break;
    case 's3_storage':
      eventsBySensorId = {
        [sensorId]: [
          ...createDummyData(
            CURRENT_MONTH,
            SEED_PARAMETERS.STORAGE_INSECT_DISTRIBUTION.CURRENT_MONTH,
          ),
          ...createDummyData(
            PREVIOUS_MONTH,
            SEED_PARAMETERS.STORAGE_INSECT_DISTRIBUTION.PREVIOUS_MONTH,
          ),
          ...createDummyData(
            PREVIOUS_YEAR,
            SEED_PARAMETERS.STORAGE_INSECT_DISTRIBUTION.PREVIOUS_YEAR,
          ),
        ],
      };
      break;
    default:
      throw new Error(`Unknown sensor ID: ${sensorId}`);
  }

  return processGeneratedEvents(eventsBySensorId)[sensorId];
};

const createWeekdayHourDistributedInsects = ({
  insectHourDistributions,
  weekdayDistribution,
}) => {
  const result = [];

  const insectGroups = insectHourDistributions.map(
    ({ insectDistribution, hourDistribution }) => ({
      hourDistribution,
      generator: buildGenerator({ insectDistribution }),
    }),
  );

  const currentDate = moment(CURRENT_MONTH);
  // move to the next Saturday (one day before Sunday)
  currentDate.add(6 - currentDate.day(), 'days');

  let count = 0;
  const insectCounts = {};

  result.push({
    timestamp: currentDate.clone().unix(),
    count,
    insect_counts: insectCounts,
  });
  // move to Sunday to start the week
  currentDate.add(1, 'days');

  const weekdayMax = Math.max(...weekdayDistribution);

  for (let day = 0; day < 7; day += 1) {
    const countOnWeekday = weekdayDistribution[day];

    for (let hour = 0; hour < 24; hour += 1) {
      // eslint-disable-next-line no-loop-func
      insectGroups.forEach(({ hourDistribution, generator }) => {
        const countInHour = hourDistribution[hour];
        const countIncrease = Math.floor(countInHour * (countOnWeekday / weekdayMax));

        count += countIncrease;

        for (let i = 0; i < countIncrease; i += 1) {
          const insect = generator.randomInsect();
          insectCounts[insect] = (insectCounts[insect] || 0) + 1;
        }
      });

      result.push({
        timestamp: currentDate.clone().hour(hour).unix(),
        count,
        insect_counts: { ...insectCounts },
      });
    }

    currentDate.add(1, 'days');
  }


  return result;
};

const buildArray = (count, buildValue) => Array(count).fill(0).map(buildValue);

// Starting with Sunday
const randomWeekdayDistribution = (base = 6, add = 3) =>
  buildArray(7, () => Math.floor(Math.random() * add) + base);

const STORAGE_WEEKDAY_DISTRIBUTION = [2, 10, 9, 7, 8, 9, 2];

const randomHourDistribution = (base = 4, add = 3) =>
  buildArray(24, () => Math.floor(Math.random() * add) + base);

const STORAGE_YUSURIKA_HOUR_DISTRIBUTION = [
  3, 4, 5, 4, 3, 2,
  1, 0, 0, 0, 0, 0,
  0, 0, 0, 1, 2, 4,
  5, 7, 9, 7, 5, 3,
];

const createRandomWeekdayHourEvents = (insectDistribution) => createWeekdayHourDistributedInsects({
  weekdayDistribution: randomWeekdayDistribution(),
  insectHourDistributions: [
    {
      insectDistribution,
      hourDistribution: randomHourDistribution(),
    },
  ],
});

const createStorageWeekdayHourEvents = () => createWeekdayHourDistributedInsects({
  weekdayDistribution: STORAGE_WEEKDAY_DISTRIBUTION,
  insectHourDistributions: [
    {
      hourDistribution: STORAGE_YUSURIKA_HOUR_DISTRIBUTION,
      insectDistribution: { yusurika_ka: 100 },
    },
    {
      hourDistribution: randomHourDistribution(3, 2),
      insectDistribution: _.omit(STORAGE_INSECT_DISTRIBUTION, 'yusurika_ka'),
    },
  ],
});

export const getAdditionalDummyEvents = () => (
  [
    {
      matchConditions: { chartType: 'weekdayBarChart', insectType: 'choubae_ka' },
      events: processGeneratedEvents({
        s1_filling: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s2_packaging: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s3_storage: createRandomWeekdayHourEvents(STORAGE_INSECT_DISTRIBUTION),
      }),
    },
    {
      matchConditions: { chartType: 'hourlyBarChart', insectType: 'choubae_ka' },
      events: processGeneratedEvents({
        s1_filling: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s2_packaging: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s3_storage: createRandomWeekdayHourEvents(STORAGE_INSECT_DISTRIBUTION),
      }),
    },
    {
      matchConditions: { chartType: 'weekdayHourMatrixChart', insectType: 'choubae_ka' },
      events: processGeneratedEvents({
        s1_filling: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s2_packaging: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s3_storage: createRandomWeekdayHourEvents(STORAGE_INSECT_DISTRIBUTION),
      }),
    },
    {
      matchConditions: { chartType: 'weekdayBarChart' },
      events: processGeneratedEvents({
        s1_filling: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s2_packaging: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s3_storage: createStorageWeekdayHourEvents(),
      }),
    },
    {
      matchConditions: { chartType: 'hourlyBarChart' },
      events: processGeneratedEvents({
        s1_filling: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s2_packaging: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s3_storage: createStorageWeekdayHourEvents(),
      }),
    },
    {
      matchConditions: { chartType: 'weekdayHourMatrixChart' },
      events: processGeneratedEvents({
        s1_filling: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s2_packaging: createRandomWeekdayHourEvents(FILLING_INSECT_DISTRIBUTION),
        s3_storage: createStorageWeekdayHourEvents(),
      }),
    },
  ]
);
