var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-picker" },
    [
      _c(
        "dropdown-menu",
        {
          attrs: { width: 400 },
          on: { activate: _vm.show },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (props) {
                  return [_vm._t("activator", null, null, props)]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tag-picker__content" },
            [
              _vm._l(_vm.model.tags, function (tag) {
                return [
                  _c(
                    "tag",
                    {
                      key: tag,
                      staticClass: "mb-2",
                      attrs: {
                        icon: "fa-remove",
                        "data-testid": "selected-tag",
                      },
                      on: {
                        click: function () {
                          return _vm.removeTag(tag)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                  ),
                ]
              }),
              _vm._v(" "),
              _vm.model.tags.length > 0
                ? _c("v-divider", { staticClass: "my-2" })
                : _vm._e(),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "tag-picker__search",
                attrs: {
                  "data-testid": "tag-search",
                  "hide-details": "",
                  placeholder: _vm.placeholder,
                  "prepend-icon": "search",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _vm._v(" "),
              _vm.enableAdd || _vm.tagCandidates.length > 0
                ? _c("v-divider", { staticClass: "my-2" })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.tagCandidates, function (tag) {
                return [
                  _c(
                    "tag",
                    {
                      key: tag,
                      staticClass: "mb-2",
                      attrs: {
                        icon: "fa-plus",
                        "data-testid": "tag-candidate",
                      },
                      on: {
                        click: function () {
                          return _vm.addTag(tag)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(tag) + "\n        ")]
                  ),
                ]
              }),
              _vm._v(" "),
              _vm.canAddTag
                ? [
                    _c(
                      "tag",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          icon: "fa-plus",
                          "data-testid": "tag-candidate",
                        },
                        on: {
                          click: function () {
                            return _vm.addTag(_vm.search)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("label.add_specific_tag", {
                                t: _vm.search,
                              })
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "tag-picker__actions" }, [
                _c(
                  "div",
                  { staticClass: "tag-picker__action" },
                  [
                    _c(
                      "round-button",
                      {
                        attrs: { icon: "fa-arrow-circle-left", fullWidth: "" },
                        on: { click: _vm.onCancel },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("button.cancel")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tag-picker__action" },
                  [
                    _c(
                      "round-button",
                      {
                        attrs: { icon: "fa-check-circle", fullWidth: "" },
                        on: { click: _vm.onSubmit },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("button.ok")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }