<template>
  <div class="term-picker">
    <dropdown-menu v-model="visible" @activate="show" :width="765">
      <template #activator="props">
        <slot name="activator" v-bind="props" />
      </template>
      <div class="term-picker__content">
        <div class="term-picker__form">
          <div class="term-picker__presets">
            <round-button
              class="term-picker__presets--today"
              icon="fa-calendar"
              fullWidth
              @click="model = today"
            >
              {{ $t('button.today') }}
            </round-button>
            <round-button
              class="term-picker__presets--yesterday"
              icon="fa-calendar"
              fullWidth
              @click="model = yesterday"
            >
              {{ $t('button.yesterday') }}
            </round-button>
            <round-button
              class="term-picker__presets--past_week"
              icon="fa-calendar"
              fullWidth
              @click="model = pastWeek"
            >
              {{ $t('button.past_7_days') }}
            </round-button>
            <round-button
              class="term-picker__presets--past_month"
              icon="fa-calendar"
              fullWidth
              @click="model = pastMonth"
            >
              {{ $t('button.past_30_days') }}
            </round-button>
          </div>
          <div class="term-picker__input-group">
            <date-picker class="term-picker__input term-picker__input--from" v-model="model.from" placeholder="from"></date-picker>
            <div class="insert-box hidden-md-and-up"><i class="fa fa-caret-down fa-2x"></i></div>
            <date-picker class="term-picker__input term-picker__input--to" v-model="model.to" placeholder="to"></date-picker>
          </div>
        </div>
        <div class="term-picker__actions mt-4">
          <div class="term-picker__action">
            <round-button icon="fa-arrow-circle-left" class="term-picker--cancel" @click="onCancel">
              {{ $t('button.cancel') }}
            </round-button>
          </div>
          <div class="term-picker__action">
            <round-button icon="fa-check-circle" class="term-picker--submit" @click="onSubmit">
              {{ $t('button.ok') }}
            </round-button>
          </div>
        </div>
      </div>
    </dropdown-menu>
  </div>
</template>

<i18n lang="yaml">
ja:
  button:
    today: '今日'
    yesterday: '昨日'
    past_7_days: '過去7日間'
    past_30_days: '過去30日間'
    cancel: 'キャンセル'
    ok: 'OK'

en:
  button:
    today: 'Today'
    yesterday: 'Yesterday'
    past_7_days: 'Past 7 days'
    past_30_days: 'Past 30 days'
    cancel: 'Cancel'
    ok: 'OK'
</i18n>

<script>
import DropdownMenu from '@/components/atoms/DropdownMenu';
import RoundButton from '@/components/atoms/RoundButton';

import DatePicker from './DatePicker';

export default {
  name: 'term-picker',
  components: {
    DatePicker,
    DropdownMenu,
    RoundButton,
  },
  props: {
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    term: {
      type: Object,
      default: () => ({ from: null, to: null }),
    },
  },
  computed: {
    pastMonth() {
      return {
        from: this.$moment().subtract(30, 'days').format(this.format),
        to: this.$moment().format(this.format),
      };
    },
    pastWeek() {
      return {
        from: this.$moment().subtract(7, 'days').format(this.format),
        to: this.$moment().format(this.format),
      };
    },
    today() {
      return {
        from: this.$moment().format(this.format),
        to: this.$moment().format(this.format),
      };
    },
    yesterday() {
      return {
        from: this.$moment().subtract(1, 'days').format(this.format),
        to: this.$moment().subtract(1, 'days').format(this.format),
      };
    },
  },
  data() {
    return {
      model: { from: null, to: null },
      visible: false,
    };
  },
  methods: {
    onCancel() {
      this.visible = false;
    },
    onSubmit() {
      this.$emit('submit', { ...this.model });
      this.visible = false;
    },
    show() {
      this.model = { ...this.term };
    },
  },
  model: {
    prop: 'term',
    event: 'submit',
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$picker-width: 765px

.term-picker
  &__content
    background-color: #f2f2f2
    padding: 16px

  &__presets
    ::v-deep .v-btn
      display: block
      margin: 8px 0

      .v-btn__content
        justify-content: left

  &__actions
    display: table
    width: 100%
    table-layout: fixed

  &__action
    display: table-cell
    padding: 0 $spacer

    ::v-deep .v-btn
      margin: 0
      width: 100%

@media #{map-get($display-breakpoints, 'md-and-up')}
  .term-picker
    &__presets,
    &__input-group
      display: inline-block
      font-size: 14px
      vertical-align: top

    &__presets
      width: 212px
      padding-right: 32px

    &__input
      display: inline-block
      width: 232px

    &__input--to
      margin-left: 24px

    &__actions
      margin: 0 auto
      width: 400px

    &__form
      font-size: 0
</style>
