var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "gallery-view" },
    [
      _c(
        "gallery-view-toolbar",
        {
          attrs: {
            editable: _vm.editable,
            event: _vm.event,
            sensor: _vm.sensor,
            canDownload: !_vm.event.updating,
            downloadOptions: _vm.downloadOptions,
            title: _vm.title,
          },
          on: {
            download: _vm.download,
            update: function (attr) {
              return _vm.$emit("updateEvent", attr)
            },
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _vm.editable
            ? [
                !_vm.event.updating
                  ? [
                      _c(
                        "toolbar-button",
                        {
                          staticClass: "hidden-sm-and-down",
                          attrs: { icon: "fa-edit", "data-testid": "edit" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.editing = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("menu.edit")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "toolbar-button",
                        {
                          attrs: { icon: "fa-trash" },
                          nativeOn: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$emit("deleteEvent")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("menu.delete")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  : _c(
                      "toolbar-button",
                      { attrs: { icon: "fa-refresh", disabled: "" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("menu.updating")) +
                            "\n      "
                        ),
                      ]
                    ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "layout",
        {
          staticClass: "mb-3",
          attrs: { hasPrevious: _vm.hasPrevious, hasNext: _vm.hasNext },
          on: {
            previous: function ($event) {
              return _vm.$emit("previous")
            },
            next: function ($event) {
              return _vm.$emit("next")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function () {
                return [
                  _c("gallery-view-header", {
                    attrs: {
                      editable: _vm.editable,
                      event: _vm.event,
                      fullscreen: _vm.fullscreen,
                    },
                    on: {
                      "update:fullscreen": function ($event) {
                        _vm.fullscreen = $event
                      },
                      updateEvent: function ($event) {
                        return _vm.$emit("updateEvent", $event)
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "side",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "result pa-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "pl-1 mb-2" },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-1",
                            attrs: {
                              label: _vm.$t("field.original"),
                              "hide-details": "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.viewOriginal,
                              callback: function ($$v) {
                                _vm.viewOriginal = $$v
                              },
                              expression: "viewOriginal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pt-2 px-2" },
                        [
                          _c("div", { staticClass: "result-item" }, [
                            _c("span", [_vm._v(_vm._s(_vm.$t("field.total")))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mx-1" }, [_vm._v(":")]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.event.count))]),
                          ]),
                          _vm._v(" "),
                          _c("insect-count-difference", {
                            staticClass: "result-item",
                            attrs: { event: _vm.event },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("threshold-alert-status", {
                        staticClass: "pa-2",
                        attrs: { event: _vm.event, sensor: _vm.sensor },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "gallery-view__image" }, [
            _c(
              "div",
              { staticClass: "gallery-view__image__container" },
              [
                _c(
                  "fullscreen",
                  {
                    staticClass: "gallery-view__image__fullscreen",
                    model: {
                      value: _vm.fullscreen,
                      callback: function ($$v) {
                        _vm.fullscreen = $$v
                      },
                      expression: "fullscreen",
                    },
                  },
                  [
                    _c("picked-insects-draw", {
                      ref: "drawer",
                      attrs: {
                        imageSrc: _vm.event.attachments["photo"],
                        detectionsUrl: _vm.detectionsUrl,
                        hideCanvas: _vm.viewOriginal,
                        originalImageWidth: _vm.ORIGINAL_IMAGE_WIDTH,
                        originalImageHeight: _vm.ORIGINAL_IMAGE_HEIGHT,
                        pickedDetectionType: "all",
                      },
                      on: { save: _vm.save },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c("replacement-notification", {
                staticClass: "mt-3",
                attrs: { sensor: _vm.sensor },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm._t("thumbnails"),
      _vm._v(" "),
      _c("insect-detections-editor", {
        attrs: {
          "content-class": "insect-detections-editor--fly-count",
          event: _vm.event,
          imageSrc: _vm.event.attachments["photo"],
          detectionsUrl: _vm.detectionsUrl,
          originalImageWidth: _vm.ORIGINAL_IMAGE_WIDTH,
          originalImageHeight: _vm.ORIGINAL_IMAGE_HEIGHT,
        },
        on: {
          updated: function ($event) {
            return _vm.$emit("pollEvent")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("content-header", {
                  staticClass: "w-100",
                  attrs: { title: _vm.title, titleClass: "title my-1" },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function () {
                        return [
                          _c("sensor-icon", { attrs: { sensor: _vm.sensor } }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "subtitle",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "overflow-ellipsis title details" },
                            [
                              _vm._v(
                                "\n            " + _vm._s(_vm.timestamp.date)
                              ),
                              _c("span", { staticClass: "ml-3" }),
                              _vm._v(
                                _vm._s(
                                  _vm.timestamp.time + " " + _vm.event.count
                                ) + "\n          "
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.editing,
          callback: function ($$v) {
            _vm.editing = $$v
          },
          expression: "editing",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }