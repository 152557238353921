var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "round-button",
        {
          attrs: { icon: "bar_chart", "data-testid": "rat_chart" },
          on: { click: _vm.openDialog },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("button.appearance_graph")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: {
            persistent: "",
            width: 1000,
            title: _vm.$t("label.rat_appearance"),
            transition: false,
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("dialog-action", { on: { click: _vm.closeDialog } }, [
                    _vm._v(_vm._s(_vm.$t("button.close"))),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialogVisible,
            callback: function ($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "rat-chart pa-4" },
            [
              _vm.loading
                ? _c("v-progress-linear", {
                    attrs: { value: _vm.loadingProgress },
                  })
                : [
                    _c("v-checkbox", {
                      attrs: {
                        label: _vm.$t("label.include_misdetections"),
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.includeMisdetections,
                        callback: function ($$v) {
                          _vm.includeMisdetections = $$v
                        },
                        expression: "includeMisdetections",
                      },
                    }),
                    _vm._v(" "),
                    _c("bar-chart", {
                      attrs: {
                        data: _vm.chartData,
                        options: _vm.chartOptions,
                        styles: { height: "400px" },
                      },
                    }),
                    _vm._v(" "),
                    _c("table", { staticClass: "value-table" }, [
                      _c("tr", [
                        _c("td", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("table.total_events"))),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.events.length)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("table.events_with_rats"))),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.eventsWithRat.length)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("table.days_installed"))),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "value" }, [
                          _vm._v(_vm._s(_vm.daysInstalled)),
                        ]),
                      ]),
                    ]),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }