var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "align-center", attrs: { dense: "" } },
    [
      _c(
        "v-col",
        [
          _c("tag-display", {
            attrs: { editable: _vm.editable, event: _vm.event },
            on: { remove: _vm.onRemoveTag },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c("gallery-view-fullscreen-enter", {
            attrs: { fullscreen: _vm.fullscreen },
            on: {
              input: function ($event) {
                return _vm.$emit("update:fullscreen", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }