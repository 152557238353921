<template>
  <data-table
    :headers="headers"
    :items="rows"
    :options.sync="options"
    :no-data-text="$t('no_data')"
    :loading="loading"
  >
    <template #item.hidden="{ item }">
      <v-simple-checkbox
        :value="item.hidden"
        @input="$emit('toggleHidden', item)"
        data-testid="hidden_checkbox"
      />
    </template>
    <template #item.last_upload_time="{ item }">
      {{ formatTimestamp(item.last_upload_time) }}
    </template>
    <template #item.recent_data="{ item }">
      <router-link
        class="text-body-1"
        :to="recentEventsUrl(item)"
      >
        {{ $t('button.show') }}
      </router-link>
    </template>
    <template #item.all_data="{ item }">
      <router-link
        class="text-body-1"
        :to="eventListUrl(item)"
      >
        {{ $t('button.show') }}
      </router-link>
    </template>
    <template #item.actions="{ item }">
      <v-btn
        icon
        @click="$emit('edit', item)"
        data-testid="edit_button"
      >
         <v-icon primary class="ma-2 material-icons">edit</v-icon>
      </v-btn>
      <v-tooltip
        left
        :disabled="item.deletable"
      >
        <template #activator="{ on, attrs }">
          <!-- Hover handlers don't work on disabled inputs (?) so attach to div around them -->
          <div
            class="d-inline-block"
            v-on="on"
            v-bind="attrs"
          >
            <v-btn
              icon
              @click="$emit('delete', item)"
              :disabled="!item.deletable"
              data-testid="delete_button"
            >
              <v-icon primary class="ma-2 material-icons">delete</v-icon>
            </v-btn>
          </div>
        </template>
        {{ $t('cannot_delete_explanation') }}
      </v-tooltip>
    </template>
  </data-table>
</template>

<i18n lang="yaml">
ja:
  column:
    hidden: 非表示
    name: 設置場所
    last_upload_time: 最終アップロード日時
    recent_data: 新着解析結果
    all_data: データ一覧
  button:
    show: '表示する'
  no_data: データがありません
  timestamp_format: 'YYYY年MM月DD日 HH:mm'
  cannot_delete_explanation: 設置場所にデータがあるので、削除できません

en:
  column:
    hidden: Hidden
    name: Installation site
    last_upload_time: Last upload time
    recent_data: New
    all_data: All Data
  button:
    show: 'Show'
  no_data: No data available
  timestamp_format: 'YYYY-MM-DD HH:mm'
  cannot_delete_explanation: The site cannot be deleted since it contains data.
</i18n>

<script>
import moment from 'moment';

import DataTable from '@/components/atoms/DataTable';
import ContentHeader from '@/components/ContentHeader';


export default {
  name: 'station-place-table',
  components: {
    ContentHeader,
    DataTable,
  },
  props: {
    places: Array,
    loading: Boolean,
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 100,
      },
    };
  },
  computed: {
    headers() {
      return [
        { text: this.$t('column.hidden'), value: 'hidden', sortable: false, width: 86 },
        { text: this.$t('column.name'), value: 'name' },
        { text: this.$t('column.last_upload_time'), value: 'last_upload_time', width: 186 },
        { text: this.$t('column.recent_data'), value: 'recent_data', sortable: false, width: 154 },
        { text: this.$t('column.all_data'), value: 'all_data', sortable: false, width: 154 },
        { text: '', value: 'actions', sortable: false, width: 110 },
      ];
    },
    rows() {
      return this.sortPlaces(this.places);
    },
  },
  methods: {
    // vue-router automatically encodes URLs with spaces and redirects to the encoded URL
    // This causes the StationEventList to be loaded twice and cancel the event retrieval request
    // Because of encode the place already in advance to prevent the additional redirect
    eventListUrl(item) {
      return `/station/${encodeURI(item.id)}`;
    },
    formatTimestamp(timestamp) {
      if (!timestamp) {
        return '--';
      }

      return moment(timestamp).format(this.$t('timestamp_format'));
    },
    recentEventsUrl(item) {
      return `/station/${encodeURI(item.id)}/recent`;
    },
    sortPlaces(places) {
      const placesWithUploadTime = places.filter(place => place.last_upload_time);
      const sortedPlacesWithUploadTime = this._.sortBy(
        placesWithUploadTime,
        'last_upload_time',
      ).reverse();

      const placesWithoutUploadTime = places.filter(place => !place.last_upload_time);
      const sortedPlacesWithoutUploadTime = this._.sortBy(placesWithoutUploadTime, 'name');

      return sortedPlacesWithUploadTime.concat(sortedPlacesWithoutUploadTime);
    },
  },
};
</script>

<style scoped lang="sass">
</style>
