<template>
  <v-card class="gallery-view--hykecam">
    <gallery-view-toolbar
      :editable="editable"
      :event="event"
      :sensor="sensor"
      :canDownload="canDownload"
      :title="title"
      @download="download"
      @update="(attr) => $emit('updateEvent', attr)"
      @close="$emit('close')">
    </gallery-view-toolbar>
    <layout
      :hasPrevious="hasPrevious"
      :hasNext="hasNext"
      @previous="$emit('previous')"
      @next="$emit('next')"
    >
      <template #top>
        <gallery-view-header
          :editable="editable"
          :event="event"
          :fullscreen.sync="fullscreen"
          @updateEvent="$emit('updateEvent', $event)"
        />
      </template>
      <div>
        <div class="media mb-2">
          <event-video
            :src="event.attachments.video"
            :thumbnail="event.attachments.thumbnail"
            :fullscreen="fullscreen"
            @enterFullscreen="fullscreen = true"
            @exitFullscreen="fullscreen = false"
            v-if="resourceType === 'video'"
          />
          <fullscreen v-model="fullscreen" v-else>
            <loading-image :src="imageUrl" :class="{ fullscreen }"/>
          </fullscreen>
        </div>
        <slot name="thumbnails" />
      </div>
    </layout>
  </v-card>
</template>

<script>
import Fullscreen from '@/components/atoms/Fullscreen';
import LoadingImage from '@/components/LoadingImage';
import EventVideo from '@/components/GalleryView/EventVideo';
import GalleryViewToolbar from '@/components/GalleryView/Toolbar';
import Layout from '@/components/GalleryView/Layout';
import GalleryViewHeader from '@/components/GalleryView/GalleryViewHeader';

export default {
  name: 'HykecamView',
  props: {
    editable: Boolean,
    event: Object,
    sensor: Object,
    hasPrevious: Boolean,
    hasNext: Boolean,
    title: String,
  },
  data() {
    return {
      fullscreen: false,
    };
  },
  inject: ['downloadAttachment'],
  components: {
    EventVideo,
    Fullscreen,
    GalleryViewHeader,
    GalleryViewToolbar,
    Layout,
    LoadingImage,
  },
  computed: {
    imageUrl() {
      return this.event.attachments.photo;
    },
    resourceType() {
      if (this.event.attachments.video) {
        return 'video';
      }
      return 'photo';
    },
    canDownload() {
      return !this.event.updating && !!this.event.attachments[this.resourceType];
    },
  },
  methods: {
    download: async function () {
      this.downloadAttachment(this.event.id, this.resourceType);
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.media
  @media #{map-get($display-breakpoints, 'md-and-up')}
    max-width: 64%
    margin: 0 auto

  .loading-image:not(.fullscreen),
  .event-video
    @media #{map-get($display-breakpoints, 'md-and-up')}
      max-width: 80%
      margin: 0 auto
</style>
