<template>
  <div class="tag-picker">
    <dropdown-menu v-model="visible" @activate="show" :width="400">
      <template #activator="props">
        <slot name="activator" v-bind="props" />
      </template>
      <div class="tag-picker__content">
        <template v-for="tag in model.tags">
          <tag
            class="mb-2"
            icon="fa-remove"
            @click="() => removeTag(tag)"
            data-testid="selected-tag"
            :key="tag"
          >
            {{tag}}
          </tag>
        </template>
        <v-divider class="my-2" v-if="model.tags.length > 0"/>
        <v-text-field
          data-testid="tag-search"
          class="tag-picker__search"
          hide-details
          :placeholder="placeholder"
          prepend-icon="search"
          v-model="search"
        />
        <v-divider class="my-2" v-if="enableAdd || tagCandidates.length > 0"/>
        <template v-for="tag in tagCandidates">
          <tag
            class="mb-2"
            icon="fa-plus"
            @click="() => addTag(tag)"
            data-testid="tag-candidate"
            :key="tag"
          >
            {{tag}}
          </tag>
        </template>
        <template v-if="canAddTag">
          <tag
            class="mt-2"
            icon="fa-plus"
            @click="() => addTag(search)"
            data-testid="tag-candidate"
          >
            {{ $t('label.add_specific_tag', { t: search }) }}
          </tag>
        </template>
        <div class="tag-picker__actions">
          <div class="tag-picker__action">
            <round-button
              icon="fa-arrow-circle-left"
              fullWidth
              @click="onCancel"
            >
              {{ $t('button.cancel') }}
            </round-button>
          </div>
          <div class="tag-picker__action">
            <round-button
              icon="fa-check-circle"
              fullWidth
              @click="onSubmit"
            >
              {{ $t('button.ok') }}
            </round-button>
          </div>
        </div>
      </div>
    </dropdown-menu>
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    search_tag: 'タグ名で検索'
    add_tag: 'タグを追加'
    add_specific_tag: '「{t}」を追加'

  button:
    cancel: 'キャンセル'
    ok: 'OK'

en:
  label:
    search_tag: 'Search Tag'
    add_tag: 'Add Tag'
    add_specific_tag: 'Add "{t}"'

  button:
    cancel: 'Cancel'
    ok: 'OK'
</i18n>

<script>
import { storeToLocalStorage, restoreFromLocaleStorage } from '@/libs/localstorage';

import DropdownMenu from '@/components/atoms/DropdownMenu';
import RoundButton from '@/components/atoms/RoundButton';
import Tag from '@/components/atoms/Tag';

const DISPLAY_LIMIT = 5;
const MAX_RECENT_TAGS = 5;

const filterTagsBySearch = (tags, search) => {
  const lowerSearchText = search.toLowerCase();
  return tags.filter((tag) => {
    const lowerTagText = tag.toLowerCase();
    return lowerTagText.includes(lowerSearchText);
  });
};

export default {
  name: 'tag-picker',
  components: {
    DropdownMenu,
    RoundButton,
    Tag,
  },
  props: {
    enableAdd: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    canAddTag() {
      return this.enableAdd &&
        !this.loading &&
        !this._.isEmpty(this.search) &&
        !this.model.tags.includes(this.search) &&
        !this.availableTags.includes(this.search);
    },
    placeholder() {
      return this.enableAdd ? this.$t('label.add_tag') : this.$t('label.search_tag');
    },
    tagCandidates() {
      if (this.search === '') {
        return this._.without(this.recentTags, ...this.model.tags);
      }

      const unselectedTags = this._.without(this.availableTags, ...this.model.tags);
      const filtered = filterTagsBySearch(unselectedTags, this.search);
      return this._.take(filtered, DISPLAY_LIMIT);
    },
  },
  data() {
    return {
      addedTags: [],
      availableTags: [],
      loading: false,
      model: { tags: [] },
      recentTags: [],
      search: '',
      visible: false,
    };
  },
  inject: {
    loadRecentTags: {
      default: () =>
        () => restoreFromLocaleStorage('recentTags') || [],
    },
    searchTags: {},
    storeRecentTags: {
      default: () =>
        tags => storeToLocalStorage('recentTags', tags),
    },
  },
  methods: {
    addTag(tag) {
      this.model.tags.push(tag);

      this.addedTags.unshift(tag);
    },
    onCancel() {
      this.visible = false;
    },
    onSubmit() {
      this.$emit('submit', [...this.model.tags]);

      const newRecentTags = this._.uniq([...this.addedTags, ...this.recentTags]);
      newRecentTags.splice(MAX_RECENT_TAGS);
      this.storeRecentTags(newRecentTags);

      this.visible = false;
    },
    removeTag(tag) {
      this.model.tags = this._.without(this.model.tags, tag);
      this.addedTags = this._.without(this.addedTags, tag);
    },
    searchAndStoreTags: async function (search) {
      this.loading = true;
      try {
        const results = await this.searchTags(search);

        this.availableTags = this._.uniq([...this.availableTags, ...results]);
      } finally {
        this.loading = false;
      }
    },
    show() {
      this.model.tags = [...this.tags];
      this.addedTags = [];
      this.recentTags = this.loadRecentTags();
      this.availableTags = this._.uniq([...this.tags, ...this.recentTags]);
      this.search = '';
    },
  },
  model: {
    prop: 'tags',
    event: 'submit',
  },
  watch: {
    search(newSearch) {
      if (newSearch !== '') {
        this.searchAndStoreTags(newSearch);
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$picker-width: 400px

.tag-picker
  height: 100%

  &__content
    background-color: #f2f2f2
    padding: 24px

  &__search
    margin-bottom: 16px
    padding: 0

    ::v-deep .input-group__prepend-icon
      padding-left: 5px

    ::v-deep input
      height: 40px

  &__actions
    display: table
    margin-top: 32px
    table-layout: fixed
    width: 100%

  &__action
    display: table-cell
    padding-right: 4px

  &__action + &__action
    padding-right: 0
    padding-left: 4px

@media #{map-get($display-breakpoints, 'md-and-up')}
  .tag-picker
    &__content
      padding: 48px
      width: 400px
</style>
