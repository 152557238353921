<template>
  <v-card class="gallery-view">
    <gallery-view-toolbar
      :editable="editable"
      :event="event"
      :sensor="sensor"
      :canDownload="!event.updating"
      :downloadOptions="downloadOptions"
      :title="title"
      @download="download"
      @update="(attr) => $emit('updateEvent', attr)"
      @close="$emit('close')"
    >
      <template v-if="editable">
        <template v-if="!event.updating">
          <toolbar-button
            class="hidden-sm-and-down"
            icon="fa-edit"
            @click.native.stop="editing = true;"
            data-testid="edit"
          >
            {{ $t('menu.edit') }}
          </toolbar-button>
          <toolbar-button
            icon="fa-trash"
            @click.native.stop="$emit('deleteEvent')"
          >
            {{ $t('menu.delete') }}
          </toolbar-button>
        </template>
        <toolbar-button icon="fa-refresh" disabled v-else>
          {{ $t('menu.updating') }}
        </toolbar-button>
      </template>
    </gallery-view-toolbar>
    <layout
      class="mb-3"
      :hasPrevious="hasPrevious"
      :hasNext="hasNext"
      @previous="$emit('previous')"
      @next="$emit('next')"
    >
      <template #top>
        <gallery-view-header
          :editable="editable"
          :event="event"
          :fullscreen.sync="fullscreen"
          @updateEvent="$emit('updateEvent', $event)"
        />
      </template>
      <div class="gallery-view__image">
        <div class="gallery-view__image__container">
          <fullscreen class="gallery-view__image__fullscreen" v-model="fullscreen">
            <picked-insects-draw
              :imageSrc="event.attachments['display-photo']"
              :detectionsUrl="detectionsUrl"
              :hideCanvas="viewOriginal"
              :pickedDetectionType="pickedDetectionType"
              :originalImageWidth='ORIGINAL_IMAGE_WIDTH'
              :originalImageHeight='ORIGINAL_IMAGE_HEIGHT'
              @save="save"
              ref="drawer"
            />
          </fullscreen>
        </div>
      </div>
      <div class="gallery-view__difference">
        <div>
          <div>
            <span>{{ $t('field.total') }}</span>
            <span class="mx-1">:</span>
            <span>{{ event.count }}</span>
          </div>
          <insect-count-difference :event="event" />
        </div>
        <hr />
        <threshold-alert-status
          class="flex-grow-1"
          :event="event"
          :sensor="sensor"
          :editable="editable"
          :showAlert="event.latest"
          :updatableThreshold="event.latest"
        />
      </div>
      <template #side>
        <div class="result pa-2">
          <v-row no-gutters align="center" justify="space-between">
            <v-checkbox
              :label="$t('field.original')"
              v-model="viewOriginal"
              hide-details
              color="primary"
              class="mt-0 pt-0"
            />
            <chart-type-select v-model="chartType" />
          </v-row>
          <insect-count-table
            v-if="chartType == 'table'"
            :insectCounts="event.insect_counts"
            :showRadiobutton="!event.updating"
            :pickedDetectionType.sync="pickedDetectionType"
            showFocusButton
            tableMaxHeight="65vh"
            @click:focus="handleFocus"
          />
          <insect-count-by-type-bar-chart
            :insectCounts="event.insect_counts"
            v-if="chartType == 'bar'"
          />
          <top-5-insect-types-pie-chart
            :insectCounts="event.insect_counts"
            v-if="chartType == 'pie'"
          />
        </div>
      </template>
    </layout>
    <slot name="thumbnails" />
    <insect-detections-editor
      :event="event"
      :imageSrc="event.attachments['display-photo']"
      :detectionsUrl="detectionsUrl"
      :originalImageWidth='ORIGINAL_IMAGE_WIDTH'
      :originalImageHeight='ORIGINAL_IMAGE_HEIGHT'
      canViewDetectionDetail
      v-model="editing"
      @updated="$emit('pollEvent')">
      <template #header>
        <content-header class="w-100" :title="title" titleClass="title my-1">
          <template #icon><sensor-icon :sensor="sensor" /></template>
          <template #subtitle>
            <div class="overflow-ellipsis title details">
              {{ timestamp.date }}<span class="ml-3" />{{ `${timestamp.time} ${event.count}`}}
            </div>
          </template>
        </content-header>
      </template>
    </insect-detections-editor>
  </v-card>
</template>

<i18n lang="yaml">
ja:
  menu:
    edit: 修正する
    delete: 削除
    updating: 更新中
  field:
    original: 撮影画像を表示
    total: 頭数合計
  msg:
    download:
      failed: 画像のダウンロードに失敗しました。

en:
  menu:
    edit: Edit
    delete: Delete
    updating: Updating
  field:
    original: Show original photo
    total: Total
  msg:
    download:
      failed: Failed to download image.
</i18n>

<script>
import moment from 'moment';
import Notifications from '@/mixins/notifications';
import TrainingDataDownload from '@/mixins/trainingDataDownload';

import Fullscreen from '@/components/atoms/Fullscreen';
import ToolbarButton from '@/components/atoms/ToolbarButton';
import ChartTypeSelect from '@/components/atoms/ChartTypeSelect';
import InsectCountByTypeBarChart from '@/components/organisms/InsectCountByTypeBarChart';
import Top5InsectTypesPieChart from '@/components/organisms/Top5InsectTypesPieChart';
import GalleryViewHeader from '@/components/GalleryView/GalleryViewHeader';
import GalleryViewToolbar from '@/components/GalleryView/Toolbar';
import Layout from '@/components/GalleryView/Layout';
import ContentHeader from '@/components/ContentHeader';
import InsectCountTable from '@/components/InsectCountTable';
import InsectDetectionsEditor from '@/components/InsectDetectionsEditor';
import InsectCountDifference from '@/components/GalleryView/InsectCountDifference';
import LoadingImage from '@/components/LoadingImage';
import SensorIcon from '@/components/SensorIcon';
import PickedInsectsDraw from '@/components/PickedInsectsDraw';
import { ZoomProvider } from '@/components/Zoom';

import ThresholdAlertStatus from './ThresholdAlertStatus';

const ORIGINAL_IMAGE_WIDTH = 5472;
const ORIGINAL_IMAGE_HEIGHT = 3648;

export default {
  name: 'FlyScanView',
  mixins: [
    Notifications,
    TrainingDataDownload,
    ZoomProvider,
  ],
  props: {
    editable: Boolean,
    event: Object,
    sensor: Object,
    hasPrevious: Boolean,
    hasNext: Boolean,
    title: String,
  },
  components: {
    ChartTypeSelect,
    ContentHeader,
    Fullscreen,
    GalleryViewHeader,
    GalleryViewToolbar,
    InsectCountByTypeBarChart,
    InsectCountDifference,
    InsectCountTable,
    InsectDetectionsEditor,
    Layout,
    LoadingImage,
    PickedInsectsDraw,
    SensorIcon,
    ThresholdAlertStatus,
    ToolbarButton,
    Top5InsectTypesPieChart,
  },
  computed: {
    timestamp() {
      return {
        date: moment.unix(this.event.timestamp).format('YYYY.M.D'),
        time: moment.unix(this.event.timestamp).format('HH:mm'),
      };
    },
    detectionsUrl() {
      return `/api/v2/events/${this.event.id}/insect_detections/photo`;
    },
    difference() {
      return this.event.count_difference;
    },
  },
  methods: {
    download: async function (type) {
      switch (type) {
        case 'trainingData': {
          this.downloadAttachment(this.event.id, 'photo');
          break;
        }
        default: {
          this.save(this.$refs.drawer);
          break;
        }
      }
    },
    save: async function (canvas) {
      try {
        await canvas.export('download.png');
      } catch (error) {
        this.notifyError('msg.download.failed');
      }
    },
    handleFocus() {
      this.$refs.drawer.focusNext();
    },
  },
  data() {
    return {
      editing: false,
      fullscreen: false,
      viewOriginal: false,
      pickedDetectionType: 'all',
      chartType: 'table',
      ORIGINAL_IMAGE_WIDTH,
      ORIGINAL_IMAGE_HEIGHT,
    };
  },
  inject: ['downloadAttachment'],
  filters: {
    sign(value) {
      if (value > 0) {
        return `+ ${value}`;
      } else if (value < 0) {
        return `- ${Math.abs(value)}`;
      }
      return '± 0';
    },
  },
  watch: {
    event(event, prev) {
      this.viewOriginal = false;
      if (event.id === prev.id) {
        this.$refs.drawer.refetch();
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.result
  border: 2px solid #ccc

.gallery-view
  &__image
    /* To fix aspect ratio */
    display: inline-block
    height: 0
    width: 80%
    margin: 0 10%
    padding-bottom: calc(80% * (1944 / 2592))
    position: relative

    &__container
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
    /* - To fix aspect ratio - */

    &__fullscreen
      height: 100%
      text-align: center
      overflow-x: auto
      overflow-y: hidden

      &.fullscreen
        height: 100vh

      ::v-deep .fullscreen-exit
        position: fixed !important

    ::v-deep .loading-image
      @media #{map-get($display-breakpoints, 'md-and-up')}
        min-height: 300px

    ::v-deep .insect-positions-canvas
      height: 100%

  &__difference
    margin: 0 10%
    padding: 1em 0.5em
    border: 2px solid #ccc
    display: flex

    hr
      height: auto
      border-color: #ccc
      border-style: solid
      margin: 0 1.5em
</style>
