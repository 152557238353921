<template>
  <v-menu
    offset-y
    v-if="downloadOptions.length > 0"
  >
    <template #activator="{ on, attrs }">
      <toolbar-button
        icon="fa-download"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t('download') }}
      </toolbar-button>
    </template>
    <v-list>
      <v-list-item
        v-for="option in downloadOptions"
        @click="$emit('download', option.type)"
        :key="option.type"
      >
        <v-list-item-title>{{ option.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <toolbar-button
    icon="fa-download"
    @click.native="$emit('download')"
    :disabled="disabled"
    v-else
  >
    {{ $t('download') }}
  </toolbar-button>
</template>

<i18n lang="yaml">
ja:
  download: 'ダウンロード'

en:
  download: 'Download'
</i18n>

<script>
import ToolbarButton from '@/components/atoms/ToolbarButton';

export default {
  name: 'DownloadToolbarButton',
  props: {
    downloadOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: Boolean,
  },
  components: {
    ToolbarButton,
  },
};
</script>
