<template>
  <div v-if="eventsLoading" class="d-flex flex-column">
    <v-progress-circular
      indeterminate
      size="100"
      class="primary--text ma-auto"
    />
  </div>
  <daily-insect-count-chart
    v-else
    :event-data="events"
    :month="month"
    :y-max="yMax"
    @max-count-updated="value => $emit('max-count-updated', value)"
  />
</template>

<script>
import moment from 'moment';
import DailyInsectCountChart from '@/components/organisms/DailyInsectCountChart';

export default {
  name: 'site-chart',
  components: {
    DailyInsectCountChart,
  },
  props: {
    eventLoader: Object,
    month: String,
    yMax: Number,
  },
  data() {
    return {
      chartEventLoader: { loading: true },
    };
  },
  computed: {
    events() {
      return this.chartEventLoader.events;
    },
    eventsLoading() {
      return this.chartEventLoader.loading;
    },
    startDate() {
      return moment(this.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
    },
    endDate() {
      return moment(this.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
    },
    requestParams() {
      return {
        timestamp_from: moment(this.startDate).startOf('day').unix(),
        timestamp_to: moment(this.endDate).add(1, 'day').startOf('day').unix(),
      };
    },
  },
  methods: {
    refreshChart() {
      this.chartEventLoader = this.eventLoader.emptyCopyWithAdditionalParams(this.requestParams);
      this.chartEventLoader.loadAll();
    },
  },
  mounted() {
    this.refreshChart();
  },
  destroyed() {
    this.chartEventLoader.cancelLoading();
  },
  watch: {
    requestParams() {
      this.refreshChart();
    },
  },
};
</script>
