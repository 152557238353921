var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tag-display" }, [
    _c(
      "div",
      { staticClass: "tag-display__icon" },
      [_c("v-icon", [_vm._v("fa-tag")])],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tag-display__tag-list" },
      _vm._l(_vm.sortedTags, function (t) {
        return _c(
          "v-chip",
          {
            key: t,
            attrs: { label: "", close: _vm.editable },
            on: {
              "click:close": function ($event) {
                return _vm.$emit("remove", t)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(t) + "\n    ")]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }