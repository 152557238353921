<template>
  <activity-legacy v-if="!featureIsActive('sensor_installation_sites')" />
  <div v-else>
    <page-title-area>
      <h1>ACTIVITY</h1>
      <template #side-content>
        <v-btn text @click="expandAllSites">
          <v-icon left color="primary">fa-chevron-circle-down</v-icon>
          {{ $t('expand_all_button') }}
        </v-btn>
      </template>
    </page-title-area>
    <query-filter
      class="mb-4"
      :loading="loading"
      :clearable="displaysAnyEvents || hasQuery"
      @apply="executeQuery"
      @clear="clearQuery"
    >
      <v-row dense class="mb-4">
        <v-col>
          <v-select
            :value="queryOfInstallationSiteType"
            @input="(value) => query.installationSiteType = value"
            :items="installationSiteTypeItems"
            :placeholder="$t('query.type')"
            class="selectbox"
            hide-details
            clearable
            outlined
            :disabled="hasInstallationSiteId"
          />
        </v-col>
        <v-col>
          <autocomplete-text-field
            allow-new-values
            clearable
            :value="queryOfInstallationSiteName"
            @input="(value) => query.installationSiteName = value"
            :label="$t('query.name')"
            :items="installationSiteNameItems"
            icon="location_searching"
            data-testid="installation_site_name_field"
            :disabled="hasInstallationSiteId"
          />
        </v-col>
        <v-col>
          <term-picker v-model="query.timePeriod">
            <template #activator="{ on, attrs }">
              <v-text-field
                :placeholder="$t('query.time_period')"
                hide-details
                outlined
                dense
                prepend-inner-icon="fa-calendar"
                readonly
                :value="timePeriodLabel"
                v-bind="attrs"
                v-on="on"
              />
            </template>
          </term-picker>
        </v-col>
        <v-col>
          <tag-picker v-model="query.tags">
            <template #activator="{ on, attrs }">
              <v-text-field
                :placeholder="$t('query.tag')"
                hide-details
                outlined
                dense
                prepend-inner-icon="local_offer"
                readonly
                v-model="tagsLabel"
                v-on="on"
                v-bind="attrs"
              />
            </template>
          </tag-picker>
        </v-col>
      </v-row>
      <div class="text-md-center">
        <div class="checkbox">
          <v-checkbox class="checkbox__input" v-model="query.pickupOnly" hide-details>
            <template v-slot:label>
              <div class="checkbox__label">
                <v-icon>star</v-icon>
                <span>{{ $t('query.pickup_only') }}</span>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="checkbox">
          <v-checkbox class="checkbox__input" v-model="query.misdetectedOnly" hide-details>
            <template v-slot:label>
              <div class="checkbox__label">
                <v-icon>sentiment_very_dissatisfied</v-icon>
                <span>{{ $t('query.misdetected_only') }}</span>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="checkbox">
          <v-checkbox class="checkbox__input" v-model="query.withoutMisdetection" hide-details>
            <template v-slot:label>
              <div class="checkbox__label">
                <span>{{ $t('query.without_misdetection') }}</span>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="checkbox">
          <v-checkbox class="checkbox__input" v-model="query.exceededOnly" hide-details>
            <template v-slot:label>
              <div class="checkbox__label">
                <span>{{ $t('query.exceeded_only') }}</span>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="checkbox">
          <v-checkbox class="checkbox__input" v-model="query.withHidden" hide-details>
            <template v-slot:label>
              <div class="checkbox__label">
                <v-icon>fa-eye-slash</v-icon>
                <span>{{ $t('query.with_hidden') }}</span>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="checkbox">
          <v-checkbox class="checkbox__input" v-model="query.bookmarkedOnly" hide-details data-testid="bookmarked_checkbox">
            <template v-slot:label>
              <div class="checkbox__label">
                <v-icon>mdi-bookmark</v-icon>
                <span>{{ $t('query.bookmarked_only') }}</span>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
    </query-filter>
    <div class="text-right pt-4">
      <insect-count-line-charts-button :sites-with-event-loaders="eventLoadersWithData" />
    </div>
    <template v-for="({ installationSite, eventLoader }, index) in eventLoadersWithData">
      <div :key="installationSite.id">
        <installation-site-events
          :installation-site="installationSite"
          :eventLoader="eventLoader"
          :expanded="expandedSiteIds.includes(installationSite.id)"
          @update:expanded="value => updateSiteExpandedState(installationSite.id, value)"
        />
        <v-divider class="mb-4 mt-8" v-if="index < eventLoadersWithData.length - 1"/>
      </div>
    </template>
  </div>
</template>

<i18n lang="yaml">
ja:
  expand_all_button: すべての設置場所を展開
  query:
    type: 設置場所種別名
    name: 設置場所名
    exceeded_only: しきい値を超えた画像
    pickup_only: ピックアップ
    with_hidden: 非表示も含む
    time_period: 期間
    tag: タグ
    misdetected_only: 誤検出
    without_misdetection: 誤検出以外
    bookmarked_only: よく見る設置場所

en:
  expand_all_button: Expand All
  query:
    type: Installation Site Type
    name: Installation Site Name
    exceeded_only: Exceeding threshold
    pickup_only: Selected
    time_period: Time period
    tag: Tag
    with_hidden: Include hidden
    misdetected_only: Misdetection
    without_misdetection: Except misdetection
    bookmarked_only: Bookmarked sites
</i18n>

<script>
import _ from 'lodash';
import { datePeriodToTimestamps } from '@/libs/time';
import { storeToLocalStorage, restoreFromLocaleStorage } from '@/libs/localstorage';
import Features from '@/mixins/features';
import SensorTypes from '@/mixins/sensorTypes';

import AutocompleteTextField from '@/components/atoms/AutocompleteTextField';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import QueryFilter from '@/components/molecules/QueryFilter';
import TagPicker from '@/components/molecules/TagPicker';
import TermPicker from '@/components/TermPicker';

import ActivityLegacy from './ActivityLegacy';
import InsectCountLineChartsButton from './InsectCountLineChartsButton';
import InstallationSiteEvents from './InstallationSiteEvents';
import { buildEventLoader } from './eventLoader';

const QUERY_KEY = 'activityQueryForInstallationSite';
export const DEFAULT_QUERY = {
  installationSiteId: null,
  installationSiteType: null,
  installationSiteName: '',
  misdetectedOnly: false,
  exceededOnly: false,
  pickupOnly: false,
  timePeriod: { from: null, to: null },
  tags: [],
  withHidden: false,
  withoutMisdetection: false,
  bookmarkedOnly: false,
};

export default {
  name: 'activity',
  mixins: [
    Features,
    SensorTypes,
  ],
  components: {
    ActivityLegacy,
    AutocompleteTextField,
    InsectCountLineChartsButton,
    InstallationSiteEvents,
    PageTitleArea,
    QueryFilter,
    TagPicker,
    TermPicker,
  },
  data() {
    const currentUserId = this.$store.getters.getCurrentUser.id;
    const storedQuery = this.restoreActivityQuery(currentUserId);
    return {
      eventLoaders: [],
      installationSiteLoader: { installationSites: [], promise: null },
      loading: false,
      expandedSiteIds: [],
      query: {
        ..._.cloneDeep(DEFAULT_QUERY),
        ...storedQuery,
        installationSiteId: this.$route.query.siteId || storedQuery.installationSiteId || null,
      },
    };
  },
  computed: {
    availableInstallationSites() {
      let { installationSites } = this.installationSiteLoader;
      if (this.queryOfInstallationSiteType) {
        installationSites = installationSites.filter(site =>
          site.type === this.queryOfInstallationSiteType,
        );
      }
      if (this.queryOfInstallationSiteName) {
        installationSites = installationSites.filter(site =>
          site.name.toLowerCase().includes(this.queryOfInstallationSiteName.toLowerCase()),
        );
      }
      if (this.query.bookmarkedOnly) {
        installationSites = installationSites.filter(site => site.bookmarked);
      }
      return installationSites;
    },
    displaysAnyEvents() {
      return this.eventLoadersWithData.length > 0;
    },
    eventLoadersWithData() {
      return this.eventLoaders.filter(({ eventLoader }) => eventLoader.events.length > 0);
    },
    hasInstallationSiteId() {
      return !!this.query.installationSiteId;
    },
    hasQuery() {
      return !this._.isEqual(this.query, DEFAULT_QUERY);
    },
    installationSiteNameItems() {
      return this.availableInstallationSites.map(site => site.name);
    },
    installationSiteTypeItems() {
      const { installationSites } = this.installationSiteLoader;
      const types = this._.uniq(installationSites.map(site => site.type));
      return this._.sortBy(types.map(type => ({
        index: this.SENSOR_TYPE_INDEXES[type],
        text: this.SENSOR_TYPE_NAMES[type],
        value: type,
      })), 'index');
    },
    queryOfInstallationSiteName() {
      const { installationSiteId } = this.query;
      const { installationSites } = this.installationSiteLoader;
      if (installationSiteId) {
        const site = installationSites.find(item => item.id === installationSiteId);
        return site ? site.name : null;
      }
      return this.query.installationSiteName;
    },
    queryOfInstallationSiteType() {
      const { installationSiteId } = this.query;
      const { installationSites } = this.installationSiteLoader;
      if (installationSiteId) {
        const site = installationSites.find(item => item.id === installationSiteId);
        return site ? site.type : null;
      }
      return this.query.installationSiteType;
    },
    tagsLabel() {
      return this.query.tags.join(', ');
    },
    timePeriodLabel() {
      const { timePeriod } = this.query;
      if (this._.isNull(timePeriod.from || timePeriod.to)) {
        return null;
      }
      return `${timePeriod.from || ''} - ${timePeriod.to || ''}`;
    },
  },
  inject: {
    fetchInstallationSites: {},
    fetchEvents: {},
    fetchEvent: {},
    deleteEvent: {},
    updateEvent: {},
    storeActivityQuery: {
      default: () => (userId, query) => {
        storeToLocalStorage(QUERY_KEY, {
          ...restoreFromLocaleStorage(QUERY_KEY),
          [userId]: query,
        });
      },
    },
    restoreActivityQuery: {
      default: () => (userId) => {
        const query = restoreFromLocaleStorage(QUERY_KEY) || {};
        return query[userId] || _.cloneDeep(DEFAULT_QUERY);
      },
    },
  },
  methods: {
    clearEvents() {
      this.eventLoaders = [];
      this.expandedSiteIds = [];
    },
    clearQuery() {
      this.clearEvents();
      this.query = _.cloneDeep(DEFAULT_QUERY);
      this.loading = false;
    },
    executeQuery: async function () {
      this.loading = true;

      this.clearEvents();
      await this.waitUntilInstallationSitesAreLoaded();
      await this.loadInitialEvents();
      const currentUser = this.$store.getters.getCurrentUser;
      this.storeActivityQuery(currentUser.id, this.query);

      this.loading = false;
    },
    expandAllSites() {
      this.expandedSiteIds = this.eventLoaders.map(({ installationSite }) => installationSite.id);
    },
    loadInitialEvents: async function () {
      const timePeriod = datePeriodToTimestamps(this.query.timePeriod);
      this.eventLoaders = this.availableInstallationSites.map(installationSite => ({
        installationSite,
        eventLoader: buildEventLoader({
          fetchEvent: this.fetchEvent,
          fetchEvents: this.fetchEvents,
          deleteEvent: this.deleteEvent,
          updateEvent: this.updateEvent,
          params: {
            installationSiteId: installationSite.id,
            exceeded_only: this.query.exceededOnly ? '1' : '0',
            pickup_only: this.query.pickupOnly ? '1' : '0',
            timestamp_from: timePeriod.from,
            timestamp_to: timePeriod.to,
            tags: this.query.tags.length > 0 ? JSON.stringify(this.query.tags) : null,
            misdetected_only: this.query.misdetectedOnly ? '1' : '0',
            with_hidden: this.query.withHidden ? '1' : '0',
            without_misdetection: this.query.withoutMisdetection ? '1' : '0',
          },
        }),
      }));
      const loadPromises = this.eventLoaders.map(({ eventLoader }) =>
        eventLoader.loadNext(4),
      );
      await Promise.allSettled(loadPromises);
    },
    updateSiteExpandedState(siteId, value) {
      if (value) {
        this.expandedSiteIds = [...this.expandedSiteIds, siteId];
      } else {
        this.expandedSiteIds = this.expandedSiteIds.filter(id => id !== siteId);
      }
    },
    waitUntilInstallationSitesAreLoaded: async function () {
      await this.installationSiteLoader.promise;
    },
  },
  created() {
    if (this.featureIsActive('sensor_installation_sites')) {
      const loadSites = async () => {
        this.installationSiteLoader.installationSites = await this.fetchInstallationSites();
      };
      this.installationSiteLoader.promise = loadSites();
    }
    if (this.$route.query.siteId) {
      this.executeQuery();
    }
  },
};
</script>

<style scoped lang="sass">
.checkbox
  display: inline-block
  margin: 0 4px

  &__input
    padding: 0
    margin: 0

  &__label
    display: flex
    align-items: center
    font-size: 14px
    white-space: nowrap
    margin-left: -4px

    &::v-deep .v-icon
      margin-right: 4px

.selectbox
  ::v-deep .v-input__slot
    min-height: 40px !important
    max-height: 40px !important

  ::v-deep .v-input__append-inner
    margin-top: 8px !important
</style>
