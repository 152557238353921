<template>
  <v-btn
    block
    class="elevation-0 tag"
    @click="$emit('click')"
  >
    <div class="tag__name"><slot /></div>
    <v-icon right class="tag__icon">{{icon}}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'tag',
  props: {
    icon: String,
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.tag
  background-color: #999!important
  border-radius: 4px
  color: #fff!important
  margin-left: 0
  margin-right: 0

  ::v-deep .v-btn__content
    text-transform: none

  &__name
    display: inline-block
    text-align: left
    width: 100%

  &__icon
    color: #fff!important
</style>
