<template>
  <div>
    <round-button
      icon="show_chart"
      data-testid="insect_count_charts"
      @click="openDialog"
      :disabled="applicableSites.length === 0"
    >
      {{ $t('display_insect_graphs') }}
    </round-button>
    <card-dialog
      persistent
      v-model="dialogVisible"
      content-class="insect-count-line-charts-dialog"
    >
      <v-container>
        <v-row justify="center">
          <month-picker v-model="month" class="text-h4" />
        </v-row>
        <v-row>
          <v-col
            class="graph mt-12"
            cols="4"
            v-for="{ installationSite, eventLoader } in applicableSites"
            :key="installationSite.id"
            data-testid="chart_area"
          >
            <!-- padding needed to display letters y, g, or Kanji properly when displayed as block -->
            <h1
              class="text-center d-block text-truncate py-1"
              :title="installationSite.name"
            >{{ installationSite.name }}</h1>
            <site-chart
              :event-loader="eventLoader"
              :month="month"
              :yMax="10"
            />
          </v-col>
        </v-row>
      </v-container>
      <template #actions>
        <dialog-action @click="close">{{ $t('close') }}</dialog-action>
      </template>
    </card-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  display_insect_graphs: '捕虫グラフ表示'
  close: '閉じる'

en:
  display_insect_graphs: 'Display Insect Graphs'
  close: 'Close'
</i18n>

<script>
import moment from 'moment';

import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import MonthPicker from '@/components/atoms/MonthPicker';
import RoundButton from '@/components/atoms/RoundButton';

import SiteChart from './SiteChart';

export default {
  name: 'insect-count-line-charts-button',
  components: {
    CardDialog,
    DialogAction,
    MonthPicker,
    RoundButton,
    SiteChart,
  },
  props: {
    sitesWithEventLoaders: Array,
  },
  computed: {
    applicableSites() {
      return this.sitesWithEventLoaders.filter(({ installationSite }) =>
        installationSite.type === 'fly' ||
          installationSite.type === 'fly_count',
      );
    },
  },
  data() {
    return {
      dialogVisible: false,
      month: moment().format('YYYY-MM'),
    };
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
    openDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="sass">
.v-dialog.insect-count-line-charts-dialog
  // Make sure Close action is always visible
  .v-card__text
    max-height: 80vh
    overflow-y: auto

  .graph
    height: 300px

    div
      height: 100%
</style>
