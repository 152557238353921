<template>
  <div class="d-md-flex">
    <div class="entity-form__form my-3">
      <v-form v-model="valid" ref="form">
        <slot name="form" :editing="editing" />
      </v-form>
    </div>
    <div class="entity-form__button-section my-6 mx-md-10 my-md-0">
      <template v-if="editing">
        <round-button
          class="sensor-form__btn my-3"
          icon="fa-check-circle"
          fullWidth
          @click="save"
        >
          {{ $t('button.save') }}
        </round-button >
        <round-button
          class="sensor-form__btn my-3"
          icon="fa-arrow-circle-left"
          fullWidth
          @click="cancel"
        >
          {{ $t('button.cancel') }}
        </round-button>
      </template>
      <template v-else>
        <round-button
          class="sensor-form__btn my-3"
          icon="fa-pencil-alt"
          fullWidth
          @click="editing = true"
        >
          {{ $t('button.edit') }}
        </round-button>
      </template>
      <slot name="buttons" :editing="editing" />
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  button:
    save: 保存
    edit: 編集
    cancel: キャンセル

en:
  button:
    save: Save
    edit: Edit
    cancel: Cancel
</i18n>

<script>
import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'entity-form',
  props: {
    onCancel: {
      type: Function,
      default: () => {},
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    startInEditing: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RoundButton,
  },
  data() {
    return {
      editing: this.startInEditing || false,
      valid: false,
    };
  },
  methods: {
    save: async function () {
      if (this.$refs.form.validate()) {
        await this.onSave();
        this.editing = false;
      }
    },
    cancel() {
      this.onCancel();
      this.editing = false;
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.entity-form
  &__button-section
    ::v-deep button
      display: flex
      width: 100%
      margin: 12px 0

@media #{map-get($display-breakpoints, 'md-and-up')}
  .entity-form
    &__form
      width: 50%

    &__button-section
      width: 240px
</style>
